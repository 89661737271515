/**
 * Re-structure the form for the footer newsletter. Nasty and fragile!
 * We get a DotDigital form that admin users drop into the CMS.
 * In order to replicate the design we need to restructure the DOM.
 * The newsletter is hidden in an accordion initially so no layout jank should happen.
 */
function restructureFooterNewsletter() {
  let $form = $('.footer-newsletter form');
  $form.addClass('footer-newsletter-form');

  // Email Section (with tickbox) - Wrap the elements.  Move input into the wrapper
  let $emailWrapper = $form.find('div').first();
  let $input = $form.find('input#btnsubmit');
  $emailWrapper.addClass('footer-newsletter-email');
  $emailWrapper.wrap("<div class='footer-newsletter-section-email'></div>");
  $input.detach().appendTo($emailWrapper);

  let $regularEmailsCheckbox = $form.find('input#ci_userConsentCheckBox');
  let $regularEmailsLabel = $regularEmailsCheckbox.next('label');
  $regularEmailsCheckbox.attr('required', 'true');
  $regularEmailsCheckbox.attr(
    'oninvalid',
    "this.setCustomValidity('Please accept our terms and conditions to proceed')"
  );
  $regularEmailsCheckbox.attr('onInput', "setCustomValidity('')");
  $regularEmailsLabel.prepend(
    "<span class='label-required'>* <span class='sr-only'>Field required</span></span>"
  );
  $regularEmailsCheckbox
    .add($regularEmailsLabel)
    .wrapAll("<div class='footer-newsletter-checkbox'></div>");
  $('.footer-newsletter-checkbox')
    .detach()
    .prependTo('.footer-newsletter-section-email');

  // Preferences section -  Wrap the heading and fieldset. Give the checkboxes a grid layout
  $('.footer-newsletter-section-email')
    .nextAll()
    .slice(0, 2)
    .wrapAll("<div class='footer-newsletter-section-preferences'></div>");
  $('.footer-newsletter-section-preferences')
    .find('div')
    .wrapAll("<div class='footer-newsletter-checkboxes'></div>");

  // Swap email & preference section
  $('.footer-newsletter-section-preferences')
    .detach()
    .insertBefore('.footer-newsletter-section-email')

  // Move description into label within the footer's preference section
  let $formDescriptions = $('.footer-newsletter-section-preferences .form-newsletter__category-description');
  $formDescriptions.each(function(){
    const $label = $(this).parent().find('label');
    const $description = $(this);

    $description.prepend('<span class="sr-only">-</span>')
    $description.appendTo($label)
  })
}


/**
 * Reveal the footer newsletter
 */
function footerNewsletterListeners() {
  let $signupButton = $('[data-js-footer-newsletter-signup]');
  let signupButtonLabelCache = $signupButton.text();

  $signupButton.on('click', function() {
    $('[data-js-footer-newsletter]')
      .stop()
      .slideToggle(function() {
        if ($('[data-js-footer-newsletter]').is(':visible')) {
          $signupButton.html('Close');
        } else {
          $signupButton.html(signupButtonLabelCache);
        }
      });
  });
}

restructureFooterNewsletter();
footerNewsletterListeners();
