<template>
  <a :href="teaser.meta.html_url"
      class="animation-icon-slide-replace | block w-full no-underline tabbing-focus:shadow-focus">


    <div class="flex">
      <div class="flex-auto flex flex-col w-full last:pr-0">
        <template v-if="shouldShowMeta">

          <div class="flex flex-row  items-center mb-10">

            <svg v-if="teaser.news_type" class="w-30 h-30 mr-10" aria-hidden="true" focusable="false">
              <use :xlink:href="`#sprite-icon-${ teaser.news_type.slug }`" />
            </svg>

            <p v-if="teaser.news_type" class="-mb-px mr-20 uppercase font-body-sans-serif-medium font-extrabold text-16 ">
              <span class="sr-only">Article type:</span> {{ teaser.news_type.name }}
            </p>

            <p class="mb-0 uppercase font-body-sans-serif text-14">
              <span v-if="eventDate"><span class="sr-only">Event date: </span>{{ eventDate }}</span>
              <span v-if="teaser.city"><span class="sr-only">City: </span>{{ teaser.city }}</span>
              <span v-if="publishedDate"><span class="sr-only">Publish date: </span>{{ publishedDate }}</span>
              <span v-if="formattedTypes" v-html="formattedTypes" class="pipe-after-element"></span>
            </p>
          </div>

        </template>

        <h3
          v-if="teaser.title"
          class="mb-20 font-heading-serif text-22 md:text-24 pr-4"
        >
          {{ teaser.title }}
        </h3>

        <p
          v-if="teaser.teaser_summary"
          class="mb-20 font-body-sans-serif text-15 text-color-grey-mid"
        >
          {{ teaser.teaser_summary }}
        </p>

        <span class="flex items-center w-20 h-10 mt-auto overflow-hidden">
          <svg
            class="flex-shrink-0 w-full h-full text-color-themed-primary icon"
          >
            <use xlink:href="#sprite-icon-right-arrow" />
          </svg>
          <svg
            class="flex-shrink-0 w-full h-full text-color-themed-primary icon"
          >
            <use xlink:href="#sprite-icon-right-arrow" />
          </svg>
        </span>
      </div>

      <div
        v-if="teaser.teaser_image_thumbnail && showImage"
        class="self-start flex-none p-5 border border-color-base-keyline md:p-10">
        <img :src="teaser.teaser_image_thumbnail.url" class="block" alt />
      </div>
    </div>
  </a>
</template>

<script>
import teaserMixin from '../../mixins/teaserMixin';

export default {
  name: 'ListingTeaser',
  props: ['teaser'],
  mixins: [teaserMixin],
  computed: {
    /**
     * formattedTypes
     *
     * If multiple types exist, wrap them in a `span` tag to allow formatting with CSS
     */
     formattedTypes() {
      let types = '';

      if(this.teaser.types) {
        types = this.teaser.types.map(type => `<span>${type.name}</span>`).join(' ');
      }

      return types;
    },
    publishedDate() {
      let publishedDate = '';

      if(this.teaser.published_date) {
        publishedDate = this.formatDate(this.teaser.published_date);
      }

      return publishedDate;
    },
    shouldShowMeta() {
      if (
        this.teaser.types && this.teaser.types.length ||
        this.eventDate ||
        this.city ||
        this.publishedDate
      ) {
        return true;
      }

      return false;
    },
    teaserIconSlug() {
      return this.teaser.news_type.slug
    }
  }
};
</script>
