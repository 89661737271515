<template>
  <PaymentSteps
    :stripe-public-key="stripePublicKey"
    :csrf-token="csrfToken"
    :endpoints="endpoints"
    :enabled-donation-options="enabledDonationOptions"
  ></PaymentSteps>
</template>

<script>
import PaymentSteps from './PaymentSteps.vue';

export default {
  name: 'PaymentRoot',
  components: {
    PaymentSteps,
  },
  provide() {
    return {
      appType: this.appType,
      stripePublicKey: this.stripePublicKey,
      csrfToken: this.csrfToken,
      endpoints: this.endpoints,
    }
  },
  props: {
    appType: {
      type: String,
      required: true,
    },
    stripePublicKey: {
      type: String,
      required: true,
    },
    csrfToken: {
      type: String,
      required: true,
    },
    endpoints: {
      type: Object,
      required: true,
    },
  },
  computed: {
    /**
     * enabledDonationOptions
     *
     * Which donation options can be displayed. These are rendered in the same
     * order as the array.
     * Accepted values are `monthly` `yearly` `one-off` `pledge` for donation app.
     * Accepted values are `yearly` `one-off` for subscrition app.
     */
    enabledDonationOptions(){
      if(this.appType == 'donation') {
        return ['pledge', 'one-off'];
      } else if(this.appType == 'subscription') {
        return ['yearly', 'one-off', ];
      }
    },
  }

}
</script>
