import $ from 'jquery';
import 'jquery-hoverintent';
import { breakpoints } from 'core/breakpoints';
import { ariaToggle } from 'numiko/a11y-toggle/a11y-toggle';

var $body = $('body');
var $mainMenuExpandItems = $('.js-primary-menu-item');
var $moreMenuBtn = $('.js-more-menu-btn');
var $mobileMenu = $('.js-mobile-navigation');
var $moreMenu = $('#header-more-menu');
var menuSlideSpeed = 300;
var hoverDelay = 200;

/*
 * Init desktop handlers
 */
function initDesktopMenu() {
  $mobileMenu.css('display', 'block');
  $moreMenu.css('display', 'none');

  /*
   * deals with revealing of submenu
   */
  function handleFocusHover(e) {
    var $target = $(e.currentTarget);
    var $subMenu = $target.find('.js-sub-menu');

    // close submenus
    if (!$target.hasClass('js-has-sub-menu')) {
      $('.js-sub-menu').slideUp('fast', function() {});
    }

    ariaToggle($target, 'aria-expanded', true);

    hideSearchPanel();
    hideMoreMenu();

    if (!$subMenu.is(':visible')) {
      toggleSubmenu($subMenu, function() {
        $body.addClass('menu-is-active');
      });
      ariaToggle($subMenu, 'aria-hidden', false);
    }
  }

  // reveal submenu on focus
  $mainMenuExpandItems.focusin(function(e) {
    handleFocusHover(e);
  });

  // Show desktop subnavs on hover
  $mainMenuExpandItems.hoverIntent({
    over: function(e) {
      handleFocusHover(e);
    },
    out: function(e) {
      var $target = $(e.currentTarget);
      var $subMenu = $target.find('.js-sub-menu');

      toggleSubmenu($subMenu, function() {
        if ($('.js-sub-menu:visible').length === 0) {
          $body.removeClass('menu-is-active');
        }
        $target.removeClass('is-active');
        ariaToggle($subMenu, 'aria-hidden', true);
      });
    },
    timeout: hoverDelay,
  });

  $moreMenuBtn.on('click', function(e) {
    var $target = $(e.currentTarget);
    var $subMenu = $('.js-more-menu');

    hideSearchPanel();
    ariaToggle(
      $target,
      'aria-expanded',
      $target.attr('aria-expanded') === 'true' ? false : true
    );
    $target.find('svg').toggleClass('hidden');

    toggleSubmenu($subMenu, function() {
      ariaToggle(
        $subMenu,
        'aria-hidden',
        $subMenu.attr('aria-hidden') === 'true' ? false : true
      );
    });
  });
}

/*
 * Slide animation for the menu
 */
function toggleSubmenu($dropdownMenu, cb) {
  $dropdownMenu.stop().slideToggle(menuSlideSpeed, function() {
    if (typeof cb === 'function') {
      cb();
    }
  });
}

/*
 * Hide Search Panel
 */

function hideSearchPanel() {
  if ($('.js-search-panel:visible').length) {
    $('.js-toggle-search-btn').trigger('click');
  }
}

/*
 * Hide "more" menu
 */

function hideMoreMenu() {
  if ($('.js-more-menu:visible').length) {
    $moreMenuBtn.trigger('click');
  }
}

/*
 * Destroy desktop handlers, reset
 */
function destroyDesktopMenu() {
  // Unbind all the hoverIntent hover events
  $mainMenuExpandItems.unbind('mouseenter').unbind('mouseleave');
  $mainMenuExpandItems.removeProp('hoverIntent_t');
  $mainMenuExpandItems.removeProp('hoverIntent_s');

  // unbind more menu click event
  $moreMenuBtn.off('click');
}

/*
 * Desktop breakpoint checker
 */
breakpoints.check({
  breakpoint: '>=lg',
  match: function() {
    initDesktopMenu();
  },
  unmatch: function() {
    destroyDesktopMenu();
  },
});
