<template>
  <form @submit.prevent="formStep1Submit">
    <div class="flex flex-wrap max-w-xl my-40">
      <label
        class="block flex-shrink-0 text-22 md:text-24  mr-2"
        for="id_amount"
      >
        Make {{ indefiniteArticle(donationOption.label)}} {{ donationOption.label }} gift of
      </label>

      <div class='flex flex-wrap items-center w-full md:w-auto'>
        <span class='text-22 md:text-24 mr-2'>&pound;</span>

        <input
          v-model.number="donationAmountModel"
          type="number"
          name="amount"
          step="1"
          placeholder="Enter amount"
          max="100000"
          min="1"
          required
          id="id_amount"
          class="no-spinner flex-grow w-full max-w-200 md:w-donate-input font-body-serif text-22 md:text-24 placeholder-color-ba-stone-300 bg-color-transparent border-b border-color-base rounded-none p-0 focus:outline-none tabbing-focus:shadow-focus"
        />
      </div>
    </div>

    <PaymentAmountButtons
      :donation-amounts="donationOption.defaultDonationAmounts"
      :total-donation-amount="totalDonationAmount"
      @set:totalDonationAmount="setDonationTotal"
    />


    <p class="max-w-xl font-body-sans-serif text-16 text-color-grey-mid my-40">
      As a charity, the British Academy welcomes donations from Fellows and friends like you. Your gifts enhance our independence as we inspire, support and promote outstanding achievement and global advances in the humanities and social sciences.
    </p>

    <slot name="additional-content-after" />

    <div class='flex flex-col flex-nowrap sm:flex-row'>
      <button
        type="submit"
        class="inline-block cursor-pointer whitespace-nowrap font-founders-grotesk font-semibold text-18 text-color-white hover:text-color-white bg-color-themed-primary hover:bg-color-black px-40 py-20 tabbing-focus:shadow-focus"
      >
        Proceed
      </button>
    </div>
  </form>

</template>

<script>
import PaymentOptionMixin from '../mixins/PaymentOptionMixin.js';

export default {
  mixins: [PaymentOptionMixin],
  data() {
    return {
      paymentPeriodInYears: '',
      paymentSchedule: '',
    }
  },
  computed: {
    /**
     * totalPaymentsCount
     *
     * @returns {Number}
     */
    totalPaymentsCount() {
      if(this.isAnnualPayment) {
        return this.paymentPeriodInYears;
      }

      if(this.isMonthlyPayment) {
        return this.paymentPeriodInYears * 12;
      }

      return 0;
    },

    /**
     * intervalPayment
     *
     * @returns {Number}
     */
    intervalPayment() {
      if(!this.totalDonationAmount || !this.totalPaymentsCount) {
        return 0;
      }

      return this.totalDonationAmount / this.totalPaymentsCount;
    }
  },
  watch: {
    donationOption: {
      handler(current, prev) {
        if(current.key !== prev.key) {
          this.setDonationTotal('');
        }
      },
      deep: true
    },
  },
  mounted() {
    this.setDonationType(this.donationOption.key);
    this.$emit('set:recurringPaymentCount', null);

    this.setDonationTotal('');
  },
  updated() {
    this.setDonationType(this.donationOption.key);
  },
  methods: {
    indefiniteArticle(donationType) {
      if (donationType == 'annual') {
        return 'an';
      }

      return 'a';
    },
  }
}
</script>
