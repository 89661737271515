<template>
  <ul v-if="validationErrors" class="list-none m-0 p-0">
    <li
      v-for="(error, index) in validationErrors"
      class="font-body-sans-serif text-16 text-color-ba-orange mt-10 mb-0"
      :key="index"
    >
      {{ error }}
    </li>
  </ul>
</template>

<script>

export default {
  props: {
    validationErrors: {
      type: Array,
      default() {
        return []
      },
    }
  }
}
</script>
