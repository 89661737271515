import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import trapFocusOnElement from 'numiko/a11y-trap-focus/a11y-trap-focus';

/**
 * The DOM that PhotoSwipe uses when initiating the lightbox
 * @type {HTMLElement}
 */
const photoSwipeDOM = document.querySelector('[data-js-photoswipe-dom]');

/**
 * An array of DOM elements on the page which, when clicked, toggle a lightbox
 * @type {Array}
 */
const lightboxItems = Array.prototype.slice.call(document.querySelectorAll('[data-js-lightbox-item]'));

/**
 * Responsible for creating a formatted object, which is then passed to PhotoSwipe as the data
 * for a given lightbox
 * @param {HTMLElement} lightboxItem the DOM element responsible for opening a lightbox
 * @returns {Object}
 */
function createLightboxItemObject(lightboxItem) {
  return {
    src: lightboxItem.getAttribute('href'),
    w: lightboxItem.getAttribute('data-js-lightbox-img-width'),
    h: lightboxItem.getAttribute('data-js-lightbox-img-height'),
    title: lightboxItem.getAttribute('data-js-lightbox-caption'),
  }
}

/**
 * Responsible for initiating the lightbox when a given lightboxItem is clicked
 * @param {HTMLElement} lightboxItem the DOM element responsible for opening a lightbox
 */
function initLightboxItem(lightboxItem) {
  // Create object to pass to PhotoSwipe
  const item = createLightboxItemObject(lightboxItem);

  // Open the lightbox
  lightboxItem.addEventListener('click', function(e) {
    e.preventDefault();

    // Set options
    const photoswipeOptions = {
      index: 0,
      bgOpacity: 1,
      showHideOpacity: true,
      allowPanToNext: false,
      loop: false,
      fullscreenEl: false,
      counterEl: false,
      shareEl: false,
      zoomEl: false,
      history: false,
      arrowEl: false,
      getThumbBoundsFn: function() {
        const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
        const rect = lightboxItem.getBoundingClientRect();

        return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
      }
    }

    // Create an instance of PhotoSwipe and open it
    const lightBox = new PhotoSwipe( photoSwipeDOM, PhotoSwipeUI_Default, [item], photoswipeOptions);
    lightBox.init();

    // Set focus back to image on close
    lightBox.listen('close', () => {
      lightboxItem.focus({
        preventScroll: true,
      });
    });
  })
}

// Initiate
if(lightboxItems.length) {
  // Initiate lightbox
  lightboxItems.forEach(initLightboxItem);

  // Trap focus inside lightbox
  trapFocusOnElement(photoSwipeDOM);
}