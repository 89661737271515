<template>
  <!-- Step 2 / your details -->
  <div id="your_details">
    <form @submit.prevent="formStep2Submit">

      <PaymentFormUserDetails
        :form-data="userDetails"
        :should-show-country-selector="isOneOffDonation"
        :server-validation-issues="serverValidationIssues"
      />

      <div class="mb-40">
        <h2 class="max-w-2xl font-heading-sans-serif text-40 lg:text-56 leading-heading text-color-themed-primary mb-20">
          Data Protection
        </h2>

        <p v-if="appType =='subscription'" class="max-w-xl font-body-sans-serif text-16 text-color-grey-mid mb-30 md:mb-50">
          The British Academy will use the data you provide on this form to process your subscription, acknowledge
          your gift and keep you updated as how your subscription has been put to good use. The British Academy is
          committed to keeping your data secure and will process your data fairly, never sharing it outside of
          the Academy without your specific consent. More details can be found in our privacy notice
          <a
            class="tabbing-focus:shadow-focus"
            href="/privacy"
            target="_blank"
          >
            here
          </a>
        </p>

        <p v-if="appType =='donation'" class="max-w-xl font-body-sans-serif text-16 text-color-grey-mid mb-30 md:mb-50">
          The British Academy will use the data you provide on this form to process your donation, acknowledge
          your gift and keep you updated as how your donation has been put to good use. The British Academy is
          committed to keeping your data secure and will process your data fairly, never sharing it outside of
          the Academy without your specific consent. More details can be found in our privacy notice
          <a
            class="tabbing-focus:shadow-focus"
            href="/privacy"
            target="_blank"
          >
            here
          </a>
        </p>

        <div v-if="appType == 'donation'">
          <div class="max-w-xl mb-10">
            <component
              :is="formStructure.anonymous.component"
              v-model="isAnonymousDonationModel"
              v-bind="formStructure.anonymous.props"
            />
          </div>

          <div class="max-w-xl mb-10">
            <component
              :is="formStructure.will_information.component"
              v-model="includeWillInformationModel"
              v-bind="formStructure.will_information.props"
            />
          </div>

          <div class="max-w-xl mb-10">
            <component
              :is="formStructure.is_no_contact.component"
              v-model="noContactDonationModel"
              v-bind="formStructure.is_no_contact.props"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-nowrap flex-col sm:flex-row -mx-3">
        <button
          type="button"
          class="flex-1 inline-block cursor-pointer whitespace-nowrap font-founders-grotesk font-semibold text-18 border border-color-base-keyline hover:border-color-theme-primary py-4 px-50 mx-3 my-10 sm:my-0 tabbing-focus:shadow-focus sm:flex-none"
          @click="formStepBack"
        >
          Back
        </button>

        <button
          type="submit"
          class="flex-1 inline-block cursor-pointer whitespace-nowrap font-founders-grotesk font-semibold text-18 text-color-white hover:text-color-white bg-color-themed-primary hover:bg-color-black py-4 px-50 mx-3 my-10 sm:my-0 tabbing-focus:shadow-focus sm:flex-none"
        >
          Proceed
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import InputText from './InputText.vue';
import InputCheckbox from './InputCheckbox.vue';
import InputSelect from './InputSelect.vue';
import PaymentFormUserDetails from './PaymentFormUserDetails.vue';

export default {
  components: {
    InputText,
    InputCheckbox,
    InputSelect,
    PaymentFormUserDetails,
  },
  props: {
    isOneOffDonation: {
      type: Boolean,
      required: true,
    },
    isAnonymousDonation: {
      type: Boolean,
      required: true
    },
    includeWillInformation: {
      type: Boolean,
      required: true
    },
    noContactDonation: {
      type: Boolean,
      required: true
    },
    userDetails: {
      type: Object,
      required: true,
    },
    serverValidationIssues: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  inject: ['appType'],
  data() {
    return {
      formStructure: {
        anonymous: {
          component: InputCheckbox,
          props: {
            label: 'I would like my donation to be anonymous',
            id: 'id_anonymous',
            inputAttributes: {
              name: 'anonymous',
            },
          },
        },
        will_information: {
          component: InputCheckbox,
          props: {
            label: 'Please send me information about including the British Academy in my Will, and the free will-writing services you offer',
            id: 'id_will_information',
            inputAttributes: {
              name: 'will_information',
            }
          },
        },
        is_no_contact: {
          component: InputCheckbox,
          props: {
            label: 'Please do not contact me about my donation',
            id: 'id_is_no_contact',
            inputAttributes: {
              name: 'is_no_contact',
            }
          },
        },
      },
    }
  },
  computed: {
    /**
     * shouldShowCountrySelector
     *
     * Determine whether to show country selection dropdown
     *
     * @returns {Boolean}
     */
    shouldShowCountrySelector() {
      return this.isOneOffDonation;
    },

    isAnonymousDonationModel: {
      get() {
        return this.isAnonymousDonation;
      },
      set(value) {
        this.$emit('set:isAnonymousDonation', value);
      }
    },
    includeWillInformationModel: {
      get() {
        return this.includeWillInformation;
      },
      set(value) {
        this.$emit('set:includeWillInformation', value);
      }
    },
    noContactDonationModel: {
      get() {
        return this.noContactDonation;
      },
      set(value) {
        this.$emit('set:noContactDonation', value);
      }
    },

  },
  methods: {
    formStepBack() {
      this.$emit('formStepBack');
    },
    formStep2Submit(event) {
      if (event.target.checkValidity()) {
        this.$emit('formStepForward');
      }
    },
  }
}
</script>
