<template>
  <form @submit.prevent="formStep1Submit">
    <p class="block flex-shrink-0 text-22 md:text-24 my-40">I would like to make a one-off payment of</p>

    <div class="inline-flex flex-col gap-6">
      <PaymentAmountButtons
        :donation-amounts="donationOption.defaultDonationAmounts"
        :donation-amounts-description="donationOption.defaultDonationAmountsDescription"
        :total-donation-amount="totalDonationAmount"
        @set:totalDonationAmount="setDonationTotal"
      />

      <div class="text-center border border-color-base-keyline py-6 px-20">
        <label
          class="block flex-shrink-0 text-18 font-bold font-founders-grotesk"
          for="id_donation_amount"
        >
          Other invoiced amount
        </label>

        <div class='flex flex-wrap items-center w-full md:w-auto'>
          <span class='text-22 md:text-24 mr-2'>&pound;</span>

          <input
            v-model.number="donationAmountModel"
            type="number"
            name="donation_amount"
            step="1"
            placeholder="Enter amount"
            max="100000"
            min="1"
            required
            id="id_donation_amount"
            class="no-spinner flex-grow w-full max-w-200 md:w-donate-input font-body-serif text-22 md:text-24 placeholder-color-ba-stone-300 bg-color-transparent border-b border-color-base rounded-none p-0 focus:outline-none tabbing-focus:shadow-focus"
          />
        </div>
      </div>
    </div>

    <slot name="additional-content-after" />

    <div class='flex flex-col flex-nowrap sm:flex-row'>
      <button
        type="submit"
        class="inline-block cursor-pointer whitespace-nowrap font-founders-grotesk font-semibold text-18 text-color-white  bg-color-themed-primary hover:text-color-white hover:bg-color-black px-40 py-20 tabbing-focus:shadow-focus"
      >
        Proceed
      </button>
    </div>
  </form>

</template>

<script>

import PaymentOptionMixin from '../mixins/PaymentOptionMixin.js';

export default {
  name: 'PaymentOptionSubscribeSingle',
  mixins: [PaymentOptionMixin],
  watch: {
    donationOption: {
      handler(current, prev) {
        if(current.key !== prev.key) {
          this.setDonationTotal('');
        }
      },
      deep: true
    },
  },
  mounted() {
    this.setDonationType(this.donationOption.key);
    this.$emit('set:recurringPaymentCount', null);

    this.setDonationTotal('');
  },
  updated() {
    this.setDonationType(this.donationOption.key);
  },
}
</script>
