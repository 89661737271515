<template>

  <div id="donation">

    <h2 class="max-w-2xl font-heading-sans-serif text-40 lg:text-56 leading-heading text-color-themed-primary mb-40 last:mb-0">
      Support the work of the British Academy
    </h2>

    <div class="max-w-xl font-body-sans-serif text-16 text-color-grey-mid mb-30" >
      <p>Thank you for considering supporting our work to bring research to a wider global audience for the benefit of all.</p>

      <p class="mb-30">
        Select whether you would like to make a single gift or a pledge to continue.
        <strong>Please note, only UK residents are able to make monthly and annual donations.</strong>
      </p>

    </div>

    <div class="flex flex-col mb-6 -mx-3 lg:flex-row lg:flex-wrap ">
      <button
        v-for="donationOption in enabledDonationOptionsData"
        :key="donationOption.key"
        class="cursor-pointer text-center block font-founders-grotesk font-semibold text-18 border border-color-base-keyline py-4 px-30 mx-3 mb-10 [ hover:border-color-themed-primary tabbing-focus:shadow-focus ][ lg:my-0 ]"
        :class="selectedDonationOptionKey === donationOption.key ? 'bg-color-themed-primary text-color-white' : 'text-color-black'"
        @click="() => $emit('set:selectedDonationOptionKey', donationOption.key)"
      >
        {{ donationOption.buttonText }}
      </button>
    </div>

    <!-- After selecting donation option -->
    <component
      v-if="selectedDonationOptionKey"
      :is="donationComponent"
      :donation-option="selectedDonationOption"
      :total-donation-amount="totalDonationAmount"
      :transaction-amount="transactionAmount"
      :is-gift-aid-selected="isGiftAidSelected"
      :is-monthly-donation="isMonthlyDonation"
      :is-annual-donation="isAnnualDonation"
      @set:recurringPaymentCount="setRecurringPaymentCount"
      @set:totalDonationAmount="(value) => $emit('set:totalDonationAmount', value)"
      @set:donationType="(value) => $emit('set:donationType', value)"
      @set:isGiftAidSelected="(value) => $emit('set:isGiftAidSelected', value)"
      @set:paymentScheduleDetailText="(value) => $emit('set:paymentScheduleDetailText', value)"
      @formStepForward="formStepForward"
    >
      <template v-slot:additional-content-after>
        <GiftAid
          :total-donation-amount="totalDonationAmount"
          :is-gift-aid-selected="isGiftAidSelected"
          @set:isGiftAidSelected="(value) => $emit('set:isGiftAidSelected', value)"
        />

        <div class="my-40">
          <div class="max-w-xl font-body-sans-serif text-16 text-color-grey-mid">
            <p>
              Thank you for supporting the British Academy. We look forward to sharing our work with you as we advance our mission.
            </p>
          </div>

          <a href="/about/contact/" class="font-founders-grotesk font-semibold underline [ hover:no-underline ]">Get in touch</a>
        </div>

      </template>
    </component>


  </div>
</template>

<script>

import PaymentOptionDonateSimple from './PaymentOptionDonateSimple.vue';
import PaymentOptionDonatePledge from './PaymentOptionDonatePledge.vue';
import InputSelect from './InputSelect.vue';
import GiftAid from './GiftAid.vue';

import PaymentStep1Mixin from '../mixins/PaymentStep1Mixin.js';
/**
 * Donation options configure donation option information required for the server and which
 * donation component to render
 *
 * @typedef {Object} DonationOption
 * @property {String} key - Option that is sent to the server
 * @property {String} label - Human readable value to display as terminology can differ from the key sent to the server
 * @property {Array<Number>} defaultDonationAmounts - Default amounts that can be selected by the user
 * @property {String} component - Which component is used to render donationOption
 * @property {Boolean} hasCount
 */


export default {
  name: "PaymentStep1Donation",
  components: {
    PaymentOptionDonateSimple,
    PaymentOptionDonatePledge,
    InputSelect,
    GiftAid,
  },
  mixins: [PaymentStep1Mixin],
  data() {
    return {

      /**
       * @type {Array<DonationOption>}
       */
      donationOptions: [
        {
          key: 'monthly',
          label: 'monthly',
          buttonText: 'Make a monthly donation',
          defaultDonationAmounts: [25, 50, 100],
          component: 'PaymentOptionDonateSimple',
          hasCount: false,
        },
        {
          key: 'yearly',
          label: 'annual',
          buttonText: 'Make an annual donation',
          defaultDonationAmounts: [50, 100, 500],
          component: 'PaymentOptionDonateSimple',
          hasCount: false,
        },
        {
          key: 'one-off',
          label: 'one-off',
          buttonText: 'Make a single gift',
          defaultDonationAmounts: [100, 150, 250],
          component: 'PaymentOptionDonateSimple',
          hasCount: false,
        },
        {
          key: 'pledge',
          label: 'pledge',
          buttonText: 'Make a recurring gift',
          defaultDonationAmounts: [100, 150, 250],
          component: 'PaymentOptionDonatePledge',
          hasCount: true,
        },
      ],
    }
  },
  computed: {
     transactionAmount() {
      if(this.donationOptionHasCount && this.recurringTransactionCount) {
        return this.getTransactionAmount();
      }

      if(this.totalDonationAmount && !this.donationOptionHasCount) {
        return this.totalDonationAmount;
      }

      return 0;
    },
  }
}
</script>
