import $ from 'jquery';
import { breakpoints } from 'core/breakpoints';
import { ariaToggle } from 'numiko/a11y-toggle/a11y-toggle';

var $menuBtn = $('.js-menu-btn');
var $subnavBtn = $('.js-mobile-sub-nav-btn');
var $mobileMenu = $('.js-mobile-navigation');
var menuSlideSpeed = 300;
var $hamburgerMenu = $('.hamburger-menu');
var $crossMenuCross = $('.hamburger-menu-cross');
var $moreMenu = $('#header-more-menu');

/*
 * Init desktop handlers
 */
function initMobileMenu() {
  $moreMenu.css('display','block');
  $mobileMenu.css('display','none');
  $hamburgerMenu.removeClass('hidden');
  $crossMenuCross.addClass('hidden');

  $menuBtn.on('click', function(e) {
    var $target = $(e.currentTarget);
    var $menu = $('.js-mobile-navigation');

    window.scrollTo(0, 0);

    ariaToggle(
      $target,
      'aria-expanded',
      $target.attr('aria-expanded') === 'true' ? false : true
    );
    $target.find('svg').toggleClass('hidden');
    hideSearchPanel();

    toggleSubmenu($menu, function() {
      // toggle no scroll class on <body> tag
      document.getElementsByTagName('body')[0].classList.toggle('no-scroll');

      ariaToggle(
        $menu,
        'aria-hidden',
        $menu.attr('aria-hidden') === 'true' ? false : true
      );
    });
  });

  $subnavBtn.on('click', function(e) {
    var $target = $(e.currentTarget);
    var $subMenu = $target
      .closest('.js-primary-menu-item')
      .find('.js-sub-menu');

    $target.find('svg').toggleClass('rotate-180');
    ariaToggle(
      $target,
      'aria-expanded',
      $target.attr('aria-expanded') === 'true' ? false : true
    );

    toggleSubmenu($subMenu, function() {
      ariaToggle(
        $subMenu,
        'aria-hidden',
        $subMenu.attr('aria-hidden') === 'true' ? false : true
      );
    });
  });
}

/*
 * Slide animation for the menu
 */
function toggleSubmenu($dropdownMenu, cb) {
  $dropdownMenu.stop().slideToggle(menuSlideSpeed, function() {
    if (typeof cb === 'function') {
      cb();
    }
  });
}

/*
 * Hide Search Panel
 */

function hideSearchPanel() {
  if ($('.js-search-panel:visible').length) {
    $('.js-toggle-search-btn').trigger('click');
  }
}

/*
 * Destroy desktop handlers, reset
 */
function destroyMobileMenu() {
  // unbind more menu click event
  $menuBtn.off('click');
  $subnavBtn.off('click');
}

/*
 * Desktop breakpoint checker
 */
breakpoints.check({
  breakpoint: '<lg',
  match: function() {
    initMobileMenu();
  },
  unmatch: function() {
    destroyMobileMenu();
  },
});
