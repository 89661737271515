<template>
  <li class="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 mb-10 md:mb-30 tabbing-focus:shadow-focus text-left">
    <button
      class="cursor-pointer text-15 text-left bg-color-transparent border-0 p-0 md:mx-4 tabbing-focus:shadow-focus"
      :class="{'font-bold  underline': isActiveFilter }"
      @click.prevent="selectFilter(filter.slug)"
    >
      {{ filter.name }}
    </button>
  </li>
</template>

<script>

export default {
  name: 'ListFiltersFilter',
  props: {
    filter: {
      type: Object,
      required: true
    },
    selectedFilters: {
      type: Array,
      required: true
    }
  },
  computed: {
    isActiveFilter() {
      return this.selectedFilters.indexOf(this.filter.slug) >= 0;
    }
  },
  methods: {

    /**
     * selectFilterGroup
     *
     * Inform parent component to select filter group
     *
     * @param {String} filterGroup
     */
    selectFilter(filter) {
      this.$emit('select-filter', filter);
    }
  }
};
</script>
