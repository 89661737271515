<template>
  <form @submit.prevent="submitForm">
    <div v-if="!stripeSecretError.error" class="mb-30 md:mb-50">
      <h2 class="max-w-2xl font-heading-sans-serif text-40 lg:text-56 leading-heading text-color-themed-primary mb-30">
        Your payment details
      </h2>

      <div class="md:bg-color-base-keyline md:p-30">
          <label class="block font-body-sans-serif text-18 mb-20" for="card-element">
            Credit or debit card
          </label>

          <div class="max-w-sm bg-color-white pb-2 pt-20 md:py-20 border-b border-solid border-black md:border-b-0 md:p-20" ref="card-element">
            <!-- A Stripe Element will be inserted here. -->
          </div>
          <input type="hidden" name="transaction_id" :value="transaction_id">

          <!-- Used to display form errors. -->
          <div
              v-if="cardError"
              class="font-body-sans-serif text-15 text-color-ba-orange mt-10 mb-0"
              role="alert">
            {{ cardError }}
          </div>
        </div>
    </div>
    <div v-else class="border border-color-error p-30 mb-30 md:mb-50">
      <h2 class="text-color-error">Error fetching payment token!</h2>

      <p v-if="stripeSecretError.msg">{{ stripeSecretError.msg }}</p>
    </div>

    <slot name="terms-and-conditions" />

    <div class="flex flex-col flex-nowrap sm:flex-row -mx-3 mt-40">
      <button
        type="button"
        class="flex-1 inline-block cursor-pointer whitespace-nowrap font-founders-grotesk font-semibold text-18 border border-color-base-keyline hover:border-color-themed-primary py-4 px-50 mx-3 my-10 sm:my-0 tabbing-focus:shadow-focus sm:flex-none"
        @click="formStepBack"
      >
        Back
      </button>

      <ButtonLoading
        type="submit"
        class="flex-1 inline-block cursor-pointer whitespace-nowrap font-founders-grotesk font-semibold text-18 text-color-white hover:text-color-white bg-color-themed-primary hover:bg-color-black py-4 px-50 mx-3 my-10 sm:my-0 tabbing-focus:shadow-focus sm:flex-none"
        :is-loading="isSubmitting"
      >
        Pay now
      </ButtonLoading>
    </div>
  </form>
</template>

<script>
import Stripe from 'Stripe';

import { fetchWrapper } from '../api/fetcher';

import ButtonLoading from './ButtonLoading.vue';

export default {
  name: 'StripePayment',
  components: {
    ButtonLoading,
  },
  inject: ['csrfToken', 'endpoints', 'stripePublicKey'],
  props: {
    serverData: {
      type: Object,
      required: true
    },
    termsAccepted: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      stripeInstance: null,
      stripeCard: null,
      stripeSecret: null,
      stripeSecretError: {
        error: false,
        msg: null,
      },
      transaction_id: null,
      cardError: null,
      isSubmitting: false,
    };
  },
  mounted() {
    this.getStripeSecret();
  },
  methods: {
    formStepBack() {
      this.$emit('formStepBack');
    },
    /**
     * getStripeSecret
     *
     * When the component mounts we need to tell stripe that we intend to make a payment.
     * In order to do this we make a request to the intent endpoint with the `intent_amount`.
     * `intent_amount` is the amount the user wishes to pay in pence. Stripe will return a
     * secret which is used to create the stripe payment element, and is sent with the users'
     * data we then send back to the stripe with the users details when they are ready to complete
     * the transaction
     */
    getStripeSecret() {
      fetchWrapper.post(
        this.endpoints.stripe.intent,
        {
          intent_amount: this.serverData.amount
        },
        this.csrfToken
      )
      .then((data) => {
        this.stripeSecretError.error = false;
        this.stripeSecret = data.secret;
        this.stripeSecretError.msg = null;

        this.createStripeClient();
      })
      .catch((error) => {
        console.error('Error', error);

        this.stripeSecretError.error = true;
        this.stripeSecretError.msg = error ? error : null;
      });
    },

    /**
     * createStripeClient
     *
     * This creates the stripe payment element when a successful payment intent has completed
     */
    createStripeClient() {
      // Create a Stripe client.
      this.stripeInstance = Stripe(this.stripePublicKey);

      // Create an instance of Elements.
      const elements = this.stripeInstance.elements();

      const style = {
        base: {
          color: '#000000',
          fontFamily: 'Graphik Light, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#CCCCCC',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      };

      // Create an instance of the card Element.
      this.stripeCard = elements.create('card', {
        hidePostalCode: true,
        style: style,
      });

      // Add an instance of the card Element into the `card-element` <div>.
      this.stripeCard.mount(this.$refs['card-element']);

      // Handle real-time validation errors from the card Element.
      this.stripeCard.addEventListener('change', (event) => {
        if (event.error) {
          this.cardError = event.error.message;
        } else {
          this.cardError = null;
        }
      });
    },

    /**
     * submitForm
     *
     * When the form is submitted, a request is made to Stripe to complete the transaction
     */
    submitForm() {
      this.isSubmitting = true;

      this.makePaymentRequest()
        .then((result) => {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            this.cardError = result.error.message;

            this.isSubmitting = false;

          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              this.setTransactionIdOnServerData(result.paymentIntent.id);

              // $nextTick is required to ensure the transaction_id has been added token
              // the `serverData` and been returned to the component
              this.$nextTick(() => {
                this.completePaymentOnWebsite();
              })
            }
          }
        });
    },

    /**
     * setTransactionIdOnServerData
     *
     * In order to include the transactionId with the user details, we need to send it to parent
     * component where the form state is stored
     *
     * @param {String} transactionId - Stripe transactionId to send with completed payment submission
     */
    setTransactionIdOnServerData(transactionId) {
      this.$emit('set:stripeTransactionId', transactionId);
    },

    /**
     * makePaymentRequest
     *
     * Make payment to stripe with stripe secret. Stripe will then return a `transaction_id`
     * on completion of a successful transaction
     */
    makePaymentRequest() {
      return this.stripeInstance
        .confirmCardPayment(this.stripeSecret, {
          payment_method: {
            card: this.stripeCard,
            billing_details: {
              name: `${this.serverData.first_name} ${this.serverData.last_name}`,
              address: {
                city: this.serverData.city,
                country: this.serverData.country,
                line1: this.serverData.address_1,
                line2: this.serverData.address_2,
                postal_code: this.serverData.postcode,
              },
              email: this.serverData.email_address,
            },
          },
        })
    },

    /**
     * On completion of a succesful Stripe payment we can then call the `stripe.complete` endpoint
     * to save the user details and transaction ID on the website. Due to GDPR there is a limited
     * amount of time that this data is held for
     */
    completePaymentOnWebsite() {
      fetchWrapper.post(
        this.endpoints.stripe.complete,
        this.serverData,
        this.csrfToken
      )
      .then((data) => {
        if(data.redirect) {
          window.location.href = data.redirect;
        }
      });
    },
  },
};
</script>
