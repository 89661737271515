module.exports = {
  breakpoints: {
    ty: '320px',
    sm: '480px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    hg: '1380px',
    mg: '1920px',
  },
  'container-gutters': {
    ty: '1.25rem',
    sm: '1.25rem',
    md: '2.5rem',
    lg: '3.125rem',
    hg: '3.125rem',
    mg: '3.125rem',
  },
  themes: {
    'ba-dark-grey': {
      primary: '#333132',
      secondary: '#ffffff',
      tertiary: '#000000',
      border: '#E0E0E0',
      'primary-300': '#3331324c' // 30% alpha channel added
    },
    'ba-stone': {
      primary: '#A5907E',
      secondary: '#000000',
      tertiary: '#ffffff',
      border: '#E0E0E0',
      'primary-300': '#A5907E4c'
    },
    'ba-yellow': {
      primary: '#ffb600',
      secondary: '#000000',
      tertiary: '#ffffff',
      border: '#E0E0E0',
      'primary-300': '#ffb6004c'
    },
    'ba-orange': {
      primary: '#fa4d09',
      secondary: '#000000',
      tertiary: '#ffffff',
      border: '#E0E0E0',
      'primary-300': '#fa4d094c'
    },
    'ba-red': {
      primary: '#fe3b1f',
      secondary: '#ffffff',
      tertiary: '#000000',
      border: '#E0E0E0',
      'primary-300': '#fe3b1f4c'
    },
    'ba-biri-red': {
      primary: '#cc472e',
      secondary: '#ffffff',
      tertiary: '#000000',
      border: '#E0E0E0',
      'primary-300': '#cc472e4c'
    },
    'ba-sky-blue': {
      primary: '#00a0df',
      secondary: '#ffffff',
      tertiary: '#000000',
      border: '#E0E0E0',
      'primary-300': '#00a0df4c'
    },
    'ba-purple': {
      primary: '#3f395f',
      secondary: '#ffffff',
      tertiary: '#000000',
      border: '#E0E0E0',
      'primary-300': '#3f395f4c'
    },
    'ba-teal': {
      primary: '#41bbc9',
      secondary: '#ffffff',
      tertiary: '#000000',
      border: '#E0E0E0',
      'primary-300': '#41bbc94c'
    },
    'ba-dark-pink': {
      primary: '#ba2777',
      secondary: '#ffffff',
      tertiary: '#000000',
      border: '#E0E0E0',
      'primary-300': '#ba27774c'
    },
    'ba-yellow-green': {
      primary: '#45ac34',
      secondary: '#ffffff',
      tertiary: '#000000',
      border: '#E0E0E0',
      'primary-300': '#45ac344c'
    },
    'ba-ecrn': {
      primary: '#e53824',
      secondary: '#ffffff',
      tertiary: '#000000',
      border: '#E0E0E0',
      'primary-300': '#e538244c'
    },
  },
};
