import * as basicScroll from 'basicscroll/src/scripts/main';
import { ariaToggle } from 'numiko/a11y-toggle/a11y-toggle';
import { smoothScroll } from 'numiko/smooth-scroll/smooth-scroll';
import browserCanUseCssVariables from 'numiko/css-variable-support/css-variable-support';

/**
 * A list of all slices on the page that have jumplink support.
 * @type {Array}
 */
const slicesWithJumplinks = Array.prototype.slice.call(
  document.querySelectorAll('[data-js-slice-jumplink]')
);

/**
 * A list of individual jumplinks; the links responsible for 'jumping' you to
 * content on a page.
 * @type {Array}
 */

const jumpLinks = Array.prototype.slice.call(
  document.querySelectorAll('[data-js-jumplink-item]')
);

/**
 * The observer responsible for firing the callback when a slice has
 * entered the top of the viewport.
 */
const progressObserver = new IntersectionObserver(updateJumplinkBar, {
  rootMargin: '0% 0% -90% 0%',
});

/**
 * The observer responsible for firing when the first slice has
 * left the viewport. Resets the jumplink label back to its original
 * state / title.
 */
const firstSliceObserver = new IntersectionObserver(resetJumplinkBar, {
  rootMargin: '0% 0% -10% 0%',
});

/**
 * The observer responsible for firing when the second slice has
 * entered the viewport. Determines when to show jumplink bar.
 */
const showJumplinksBarObserver = new IntersectionObserver(showJumplinksBar, {
  rootMargin: '0% 0% -10% 0%',
});

/**
 * The button responsible for opening / closing the jumplink bar.
 * @type HTMLElement
 */
const dropdownButton = document.querySelector('[data-js-dropdown-button]');

/**
 * The HTML element that is our jumplink bar
 * @type HTMLElement
 */
const fixedJumplinksBar = document.querySelector(
  '[data-js-jumplinks-container]'
);

/**
 * The HTML element responsible for displaying the currently-active
 * jumplink
 * @type HTMLElement
 */
const jumpLinkStatus = document.querySelector(
  '[data-js-jumplink-current-section]'
);

/**
 * The HTML element responsible for scrolling the page back to the top. This
 * particular 'scroll to top' element lives within the jumplinks bar itself.
 * @type HTMLElement
 */
const jumplinkBackToTopLink = document.querySelector('[data-js-jumplink-back-to-top]');

/**
 * The HTML element used to calculate the length of the main content for progress bar
 * @type HTMLElement
 */
const mainPageContent = document.querySelector('[data-js-main-content]');

/**
 * The HTML element to display the progress scrolled through the content
 * @type HTMLElement
 */
const progressBar = document.querySelector('[data-js-progress-bar]');

/**
 * The default label for the jumplink's status bar; initialised on load if
 * one exists
 * @type String
 */
let defaultJumpLinkStatusLabel;

/**
 * The callback used to set the title and underline style in the fixed jumplink
 * bar
 * @param {Array} entries a list of entries from the Intersection Observer
 */
function updateJumplinkBar(entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const id = entry.target.getAttribute('id');
      const intersectingJumpLinks = Array.prototype.slice.call(
        document.querySelectorAll(`a[href="#${id}"]`)
      );

      jumpLinks.forEach(link => {
        const linkTitle = link.querySelector('[data-js-jumplink-item-title]');
        linkTitle.classList.remove('underline');
      });

      intersectingJumpLinks.forEach(link => {
        const linkTitle = link.querySelector('[data-js-jumplink-item-title]');
        linkTitle.classList.add('underline');
      });

      const sliceLabel = entry.target.getAttribute('data-js-slice-label');
      jumpLinkStatus.innerText = sliceLabel;
    }
  });
}

/**
 * Resets the jumplink label back to its original if it is not in view and
 * it is below the viewport.
 * @param {Array} entries a list of entries from the Intersection Observer
 */
function resetJumplinkBar(entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting === false && entry.boundingClientRect.y > 0) {
      // Remove underline from active jumplink
      jumpLinks.forEach(link => {
        const linkTitle = link.querySelector('[data-js-jumplink-item-title]');
        linkTitle.classList.remove('underline');
      });

      // Reset active label back to default
      if (jumpLinkStatus.innerText !== defaultJumpLinkStatusLabel) {
        jumpLinkStatus.innerText = defaultJumpLinkStatusLabel;
      }
    }
  });
}

/**
 * Determines when the jumpling bar should slide in and out of view
 * Shows when the second slice comes into view
 * @param {Array} entries
 */
function showJumplinksBar(entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting === false && entry.boundingClientRect.y > 0) {
      fixedJumplinksBar.classList.add('translate-y-full');
    } else {
      fixedJumplinksBar.classList.remove('translate-y-full');
    }
  });
}

/**
 * Responsible for adding additional padding to the footer based on
 * the element passed in
 * @param {HTMlElement} element the element who's height to use to create additional space
 */
function createFooterSpace(element) {
  const footer = document.querySelector('[data-js-site-footer]');
  const heightOfElement = element.offsetHeight;

  footer.style.paddingBottom = heightOfElement + 'px';
}

/**
 * Responsible for enabling dropdown functionality on a given element.
 * We are making use of jQuery here because we need to make use of the
 * slideToggle behaviour. We also want to make use of the currently-existing
 * aria functionality on the site.
 * @param {HTMLElement} dropdownButton the element to use as a dropdown toggle
 */
function registerDropdownButton(dropdownButton) {
  dropdownButton.addEventListener('click', function() {
    /**
     * The button responsible for toggling the collapsed
     * jumplinks
     * @type jQuery
     */
    const $button = $(this);

    /**
     * The ID of the element we want to expand or collapse,
     * when the above button is clicked
     * @type Number
     */
    const elementToToggleId = $button.attr('aria-controls');

    /**
     * The element we want to expand / collapse
     * @type jQuery
     */
    const $elementToToggle = $('#' + elementToToggleId);

    /**
     * The icon that sits within our button; rotated on click
     * @type jQuery
     */
    const $svgIcon = $button.find('svg');

    /**
     * Rotate the icon class
     */
    $svgIcon.toggleClass('rotate-180');

    /**
     * Set the aria attributes on our button and expandable content
     */
    $elementToToggle.stop().slideToggle(400, function() {
      ariaToggle($button, 'aria-expanded', $elementToToggle.is(':visible'));
      ariaToggle(
        $elementToToggle,
        'aria-hidden',
        $elementToToggle.is(':hidden')
      );
    });
  });
}

/**
 * Runs when a jumplink label is clicked; responsible for
 * scrolling to the desired slice and updating the push state.
 * @param {EventObject} e the event object
 */
function onJumpLinkClick(e) {
  e.preventDefault();

  /**
   * The element that was clicked (a jumplink label)
   * @type {HTMLElement}
   */
  const element = e.currentTarget;

  /**
   * The HREF attribute of the jumplink label. Used to
   * associate the label with the ID attribute of a jumplink slice.
   * @type {String}
   */
  const elementHref = element.getAttribute('href');

  /**
   * Close the jumplink bar
   */
  $(dropdownButton).trigger('click');

  /**
   * Scroll to the element and set push state
   */
  smoothScroll(elementHref, 0);
}

/**
 * createProgressBar
 *
 * Creates progress bar to show how far user has scrolled through the main content
 */
function createProgressBar() {
  const progressBarScroller = basicScroll.create({
    elem: mainPageContent,
    direct: progressBar,
    from: 'top-top',
    to: 'bottom-bottom',
    props: {
      '--progress': {
        from: '-100%',
        to: '0%',
      },
    },
  });

  progressBarScroller.start();
}

/**
 * Runs when the back to top link is clicked within the jumplink
 * bar. Responsible for closing the jumplink bar, if expanded.
 */
function onBackToTopClick() {
  const isExpanded = (dropdownButton.getAttribute('aria-expanded') === 'true');

  // Close the jumplink bar if it is expanded
  if(isExpanded) {
    $(dropdownButton).trigger('click');
  }
}

// Initiate jumplinks
if (slicesWithJumplinks.length) {
  /**
   * Cache the default jump link label
   */
  defaultJumpLinkStatusLabel = jumpLinkStatus.innerText;

  /**
   * Handles registering a button as a dropdown, which toggles
   * the jumplink content
   */
  registerDropdownButton(dropdownButton);

  jumplinkBackToTopLink.addEventListener('click', onBackToTopClick);

  /**
   * Ensure the fixed jumplink bar doesn't overlap
   * the contents of the footer
   */
  createFooterSpace(fixedJumplinksBar);

  // Register event handlers for each jumplink.
  jumpLinks.forEach(jumpLink => {
    jumpLink.addEventListener('click', onJumpLinkClick);
  });

  // Create progress bar to show how far the user has scrolled through the main content
  if(browserCanUseCssVariables()) {
    createProgressBar();
  }

  /**
   * Observe the first slice; this is required because if this is not
   * in view, and it's below the viewport, we want to reset the status label
   */
  firstSliceObserver.observe(slicesWithJumplinks[0]);

  /**
   * Observe the first slice. This is used to determine when to show / hide
   * jumplink bar
   */
  showJumplinksBarObserver.observe(slicesWithJumplinks[0]);

  /**
   * Observe all jumplink slices on the page; when they enter the viewport,
   * we need to update the jumplink status label
   */
  slicesWithJumplinks.forEach(slice => {
    progressObserver.observe(slice);
  });
}
