import { breakpoints } from '../../config/config';
import Macy from 'macy';
import _debounce from 'lodash/debounce';

const masonryBlock = document.querySelectorAll('[data-js-masonry]');

const initMasonry = () => {
  if (masonryBlock.length > 0) {
    const breakMedium = breakpoints.md.replace('px', '');
    const breakSmall = breakpoints.sm.replace('px', '');

    masonryBlock.forEach((element) => {
      buildMasonry({ element, breakMedium, breakSmall });
    });
  }
};

/*
** @param {Object} element - Masonry object
** @param {String} breakMedium - Medium breakpoint size
** @param {String} breakSmall - Small breakpoint size
*/
const buildMasonry = ({ element, breakMedium, breakSmall }) => {
  const breakAt = {
    [breakMedium]: {
      columns: 3,
      margin: 50
    },
    [breakSmall]: {
      columns: 2,
      margin: 20
    }
  };

  const macyInstance = Macy({
    container: element,
    trueOrder: true,
    mobileFirst: true,
    margin: 20,
    columns: 1,
    breakAt
  });

  eventListeners(macyInstance, breakSmall);
}

/*
** @param {Object} element - Macy instance object
** @param {String} size - Small breakpoint size
*/
const eventListeners = (element, size) => {
  window.addEventListener('load', () => {
    if (window.innerWidth <= size) {
      element.remove();
    }
  });

  window.addEventListener('resize', _debounce(() => {
    if (window.innerWidth <= size) {
      element.remove();
    }

    if (window.innerWidth > size) {
      element.reInit();
    }
  }, 500));
}

initMasonry();
