<template>
  <div class="mb-50 md:mb-80">
    <h2 class="max-w-2xl font-heading-sans-serif text-40 lg:text-56 leading-heading text-color-themed-primary mb-20">
      Please enter your personal details
    </h2>

    <div class="w-full max-w-4xl">
      <div class="md:flex md:flex-wrap">
        <template v-for="(value, key) in formData">
          <component
            v-if="showField(key)"
            :key="key"
            :is="formStructure[key].component"
            :validation-errors="serverValidationIssues[key]"
            class="md:w-1/2 md:pr-20"
            v-model="formData[key]"
            v-bind="formStructure[key].props"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { countryCodes } from '../utility/countryCodes';
import InputText from './InputText.vue';
import InputCheckbox from './InputCheckbox.vue';
import InputSelect from './InputSelect.vue';

export default {
  components: {
    InputText,
    InputCheckbox,
    InputSelect,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    shouldShowCountrySelector: {
      type: Boolean,
      required: true,
    },
    serverValidationIssues: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      formStructure: {
        title: {
          component: InputText,
          props: {
            label: 'Title',
            id: 'id_title',
            class: 'md:mr-1',
            inputAttributes: {
              type: 'text',
              name: 'title',
              maxlength: '15',
              autocomplete: 'honorific-prefix',
              placeholder: 'Title*',
              required: 'required',
            }
          },
        },
        first_name: {
          component: InputText,
          props: {
            label: 'First name',
            id: 'id_first_name',
            inputAttributes: {
              type: 'text',
              name: 'first_name',
              maxlength: '50',
              autocomplete: 'given-name',
              placeholder: 'First name*',
              required: 'required',
            }
          },
        },
        last_name: {
          component: InputText,
          props: {
            label: 'Last name',
            id: 'id_last_name',
            inputAttributes: {
              type: 'text',
              name: 'last_name',
              autocomplete: 'family-name',
              maxlength: '50',
              placeholder: 'Last name*',
              required: 'required',
            }
          },
        },
        postcode: {
          component: InputText,
          props: {
            label: 'Postcode',
            id: 'id_postcode',
            class: 'md:mr-1',
            inputAttributes: {
              type: 'text',
              name: 'postcode',
              maxlength: '15',
              autocomplete: 'postal-code',
              placeholder: 'Postcode*',
              required: 'required',
            }
          },
        },
        address_1: {
          component: InputText,
          props: {
            label: 'Address line 1',
            id: 'id_address_1',
            inputAttributes: {
              type: 'text',
              name: 'address_1',
              maxlength: '100',
              autocomplete: 'street-address',
              placeholder: 'Address line 1*',
              required: 'required',
            }
          },
        },
        address_2: {
          component: InputText,
          props: {
            label: 'Address line 2',
            id: 'id_address_2',
            inputAttributes: {
              type: 'text',
              name: 'address_2',
              maxlength: '100',
              autocomplete: 'address-line2',
              placeholder: 'Address line 2',
            }
          },
        },
        town_city: {
          component: InputText,
          props: {
            label: 'Town/City',
            id: 'id_town_city',
            inputAttributes: {
              type: 'text',
              name: 'town_city',
              maxlength: '50',
              placeholder: 'Town/City*',
              autocomplete: 'address-level2',
              required: 'required',
            }
          },
        },
        county: {
          component: InputText,
          props: {
            label: 'County',
            id: 'id_county',
            inputAttributes: {
              type: 'text',
              name: 'county',
              maxlength: '50',
              placeholder: 'County',
              autocomplete: 'address-level1',
            }
          },
        },
        country: {
          component: InputSelect,
          props: {
            label: 'Country',
            id: 'id_country',
            class: 'md:mr-1',
            defaultOption: 'Select Country*',
            options: countryCodes,
            inputAttributes: {
              name: 'country',
              autocomplete: 'country',
              required: 'required',
            }
          },
        },
        email_address: {
          component: InputText,
          props: {
            label: 'Email address',
            id: 'id_email_address',
            type: 'email',
            inputAttributes: {
              name: 'email_address',
              maxlength: '255',
              placeholder: 'Email address*',
              required: 'required',
              autocomplete: 'email',
            }
          },
        },
      },
    }
  },
  methods: {
    /**
     * Logic to determine whether a field should be displayed
     * If there are no conditions set for the field then always display it
     *
     * @param {String} fieldKey - key to check against
     * @returns {Boolean}
     */
    showField(fieldKey) {
      const fieldDisplayConditions = {
        country: this.shouldShowCountrySelector,
      }

      if(fieldKey in fieldDisplayConditions) {
        return fieldDisplayConditions[fieldKey];
      }

      return true;
    }
  }
}
</script>
