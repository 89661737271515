<template>
  <div>
    <div class="flex items-start form-checkbox">
      <input
        v-model="model"
        type="checkbox"
        :id="id"
        class="mr-4 mt-2 tabbing-focus:shadow-focus"
        v-bind="inputAttributes"
      />
      <label class="font-body-sans-serif text-16 text-color-grey-mid" :for="id">
        {{ label }}
        <template v-if="inputAttributes.required">
          <sup aria-hidden="true">*</sup>
        </template>
      </label>
    </div>

    <InputValidationMessage
      v-if="validationErrors"
      :validation-errors="validationErrors"
    />
  </div>
</template>

<script>

import InputValidationMessage from './InputValidationMessage.vue'

export default {
  components: {
    InputValidationMessage,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      default: 'checkbox',
    },
    inputAttributes: {
      type: Object,
      default() {
        return {}
      }
    },
    label: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    validationErrors: {
      type: Array,
      default() {
        return []
      },
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
  }
}
</script>
