export default {
  props: [
    'showImage',
  ],
  data() {
    return {
      monthNames: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    };
  },
  computed: {
    eventDate() {
      let eventDate = '';

      // If the teaser start & end date is the same, or, no end date...
      if(this.teaser.start_date && this.teaser.start_date === this.teaser.end_date || this.teaser.start_date && this.teaser.end_date === null) {
        eventDate = this.formatDate(this.teaser.start_date);
      } else if(this.teaser.start_date && this.teaser.end_date) {
        // If there is both a start AND and an end date...
        eventDate = this.formatDateRange(this.teaser.start_date, this.teaser.end_date);
      }

      return eventDate;
    }
  },
  methods: {
    /**
     * formatDate
     *
     * Formats date to human friendly version.
     * Example: 30 Jul 2020
     *
     * @param {String} rawDate
     * @returns {String}
     */
     formatDate(rawDate) {
      const date = new Date(rawDate);
      const formattedDate = `${date.getDate()} ${this.monthNames[date.getMonth()]} ${date.getFullYear()}`;

      return formattedDate;
    },
    /**
     * formatDateRange
     *
     * Formats date range to human friendly version
     *
     * Start and end year and month are the same:
     *  - 20 - 30 Jul 2020
     * Start and end year are the same but the months are different:
     *  - 20 Jul - 10 Aug 2020
     * Start year and end year are different:
     *  - 30 Jul 2020 - 30 Jul 2021
     *
     * @param {String} rawStartDate
     * @param {String} rawEndDate
     * @returns {String}
     */
     formatDateRange(rawStartDate, rawEndDate) {
      const startDate = new Date(rawStartDate);
      const endDate = new Date(rawEndDate);
      let formattedDate;

      if(startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
        // Check event range is the same month and year
        formattedDate = `${startDate.getDate()} - ${this.formatDate(rawEndDate)}`;
      } else if (startDate.getYear() === endDate.getYear()) {
        // Check event range is the same month
        formattedDate = `${startDate.getDate()} ${this.monthNames[startDate.getMonth()]} - ${this.formatDate(rawEndDate)}`;
      } else {
        // If the date isn't the same month or year then show full dates
        formattedDate = `${this.formatDate(rawStartDate)} - ${this.formatDate(rawEndDate)}`
      }

      return formattedDate;
    }
  }
}