<template>
  <ul class="flex flex-col md:flex-row list-none p-0 m-0">
    <ListFiltersCategory
      v-for="(group, key) in filters"
      :group="group"
      :active-filters="activeFilters"
      :selected-filter-group-id="selectedFilterGroupId"
      :filter-group-transition-name="filterGroupTransitionName"
      :key="key"
      @close-filter-group="closeFilterGroup"
      @select-filter-group="selectFilterGroup"
      @select-filter="selectFilter"
    />
  </ul>
</template>

<script>

import _debounce from 'lodash/debounce';
import config from '../../../config/config';
import ListFiltersDropdown from './ListFiltersDropdown.vue';
import ListFiltersCategory from './ListFiltersCategory.vue';

export default {
  name: 'ListFiltersCategories',
  components: {
    ListFiltersDropdown,
    ListFiltersCategory
  },
  props: {
    filters: {
      type: Array,
      required: true
    },
    activeFilters: {
      type: Object,
      required: true
    },
    selectedFilterGroupId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filterGroupTransitionName: ''
    }
  },
  mounted() {
    this.setFilterGroupTransitionName();

    this.$nextTick(() => {
      window.addEventListener('resize', this.setFilterGroupTransitionName);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setFilterGroupTransitionName);
  },
  methods: {

    /**
     * setFilterGroupTransitionName
     *
     * Filter groups have different transitions at different breakpoints
     * Debounced function to prevent too many checks of window.innerWidth
     */
    setFilterGroupTransitionName: _debounce(function() {
      this.filterGroupTransitionName = window.innerWidth > parseInt(config.breakpoints.md) ? 'slide-down-fade' : 'scale-fade';
    }, 200),

    /**
     * closeFilterGroup
     *
     * Informs parent component when filter group is closed
     */
    closeFilterGroup() {
      this.$emit('close-filter-group');
    },

    /**
     * selectFilterGroup
     *
     * Informs parent component when filter group is selected
     *
     * @param {String} filterGroup
     */
    selectFilterGroup(filterGroup) {
      this.$emit('select-filter-group', filterGroup);
    },

    /**
     * selectFilter
     *
     * Informs parent component when filter is selected
     *
     * @param {String} category
     * @param {String} filter
     */
    selectFilter(category, filter) {
      this.$emit('select-filter', category, filter);
    },
  }
}
</script>
