<template>
  <div class="container relative z-1 px-20 mx-auto md:px-40 lg:px-50 mt-30 mb-50 md:mt-50 md:mb-70 lg:mt-70 lg:mb-80">
    <div class="relative md:border md:border-color-base-keyline">
      <div class="relative z-1 flex flex-col md:flex-row bg-color-white">
        <h3 class="block font-heading-serif-italic text-18 text-color-grey-mid md:border-r md:border-color-base-keyline md:px-40 md:py-20 md:m-0">Filter by</h3>

        <ListFiltersCategories
          :filters="filters"
          :active-filters="activeFilters"
          :selected-filter-group-id="selectedFilterGroupId"
          @close-filter-group="closeFilterGroup"
          @select-filter-group="selectFilterGroup"
          @select-filter="selectFilter"
        />

        <ListFiltersSorting
          v-if="ordering"
          :ordering="ordering"
          :order-by="orderBy"
          @close-filter-group="closeFilterGroup"
          @change-order-by="changeOrderBy"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ListFiltersCategories from './ListFiltersCategories.vue';
import ListFiltersSorting from './ListFiltersSorting.vue';

export default {
  name: 'ListFilters',
  components: {
    ListFiltersCategories,
    ListFiltersSorting
  },
  props: {
    filters: {
      type: Array,
      required: true
    },
    activeFilters: {
      type: Object,
      required: true
    },
    ordering: {
      type: Object,
      required: true
    },
    orderBy: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedFilterGroupId: '',
    };
  },
  methods: {
    /**
     * selectFilterGroup
     *
     * toggle filter group visibility so only one is shown at a time
     *
     * @param {String} id
     */
    selectFilterGroup(id) {
      // this will toggle on/off a filterGroup visibility
      this.selectedFilterGroupId = this.selectedFilterGroupId === id ? '' : id;

      if (this.selectedFilterGroupId !== '') {
        document.getElementsByTagName('body')[0].classList.add('no-scroll', 'md:scrolls');
      } else {
        this.closeFilterGroup();
      }
    },

    /**
     * selectFilter
     *
     * Inform parent component when a filter has been selected and close filter group
     *
     * @param {String} category
     * @param {String} filter
     */
    selectFilter(category, filter) {
      this.$emit('select-filter', category, filter);
      this.closeFilterGroup();
    },

    /**
     * closeFilterGroup
     *
     * Close filter group dropdown
     */
    closeFilterGroup() {
      this.selectedFilterGroupId = '';
      document.getElementsByTagName('body')[0].classList.remove('no-scroll', 'md:scrolls');
    },

    /**
     * changeOrderBy
     *
     * Inform parent component when orderType has changed
     *
     * @param {String} orderType
     */
    changeOrderBy(orderType) {
      this.$emit('change-order-by', orderType);
    },
  },
};
</script>
