/*
 * Set the dimensions of the iframe
 */
function setDimensions($videoContainer) {
  const videoAspectRatio = 16 / 9;
  const containerWidth = $videoContainer.width();
  const containerHeight = $videoContainer.height();
  const containerAspectRatio = containerWidth / containerHeight;
  const $iframe = $videoContainer.find('iframe');

  $iframe.css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  });

  /**
   * Compare the aspect ratio of the container to the video aspect ratio (16/9)
   *
   * If the container aspect ratio is smaller than or equal to that of the
   * video, set the height of the iframe to 100% and the width (in px) to the
   * container height multiplied by the video aspect ratio.

   * If the container aspect ratio is larger than that of the video, set
   * the width of the iframe to 100% and the height (in px) to the container
   * width divided by the video aspect ratio.
   */
  if (containerAspectRatio <= videoAspectRatio) {
    const width = containerHeight * videoAspectRatio;

    $iframe.css({
      height: '100%',
      width: `${width}px`
    });
  } else {
    const height = containerWidth / videoAspectRatio;

    $iframe.css({
      width: '100%',
      height: `${height}px`
    });
  }
}

export { setDimensions };