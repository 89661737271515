<template>
  <div class="flex flex-wrap mb-6 -m-1 last:mb-0 lg:-m-3">
    <!-- pre-prepared donation amounts -->
    <button
      v-for="donationValue, index in donationAmounts"
      type="button"
      :key="donationValue"
      class="flex-1 cursor-pointer text-center block font-heading-serif-italic text-24 leading-heading border border-color-base-keyline py-6 px-20 m-1 [ hover:border-color-themed-primary tabbing-focus:shadow-focus ] [ md:text-56 ] [ lg:flex-none lg:px-30 lg:m-3 ]"
      :class="[isAmountSelected(donationValue) ? 'bg-color-black text-color-white' : 'bg-color-white text-color-black']"
      @click="$emit('set:totalDonationAmount', donationValue);"
    >
      <span class="block">{{ formatCurrencyString(donationValue) }}</span>
      <span class="block font-founders-grotesk font-semibold text-18 mt-4" v-if="donationAmountsDescription">({{ getDonationAmountDescription(index)}})</span>
    </button>
  </div>
</template>

<script>

export default {
  props: {
    donationAmounts: {
      type: Array,
      required: true,
    },
    donationAmountsDescription: {
      type: Array,
      required: false,
    },
    totalDonationAmount: {
      type: [Number, String],
      required: true,
    },
  },
  methods: {
    isAmountSelected(donationValue) {
      return donationValue === this.totalDonationAmount;
    },
    getDonationAmountDescription(index) {
      return this.donationAmountsDescription[index];
    },
    /**
     * formatCurrencyString
     *
     * @param {Number} - currencyValue
     * @returns {String}
     */
     formatCurrencyString(currencyValue) {
      let minimumFractionDigits = 2;
      let maximumFractionDigits = 2;

      if(Number.isInteger(currencyValue)) {
        minimumFractionDigits = 0;
        maximumFractionDigits = 0;
      }

      return Number(currencyValue).toLocaleString('en', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits,
        maximumFractionDigits,
      });
    },
  }
}
</script>
