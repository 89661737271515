const countdownDates = document.querySelectorAll('[data-js-countdown]');

const initCount = () => {
  if (countdownDates.length > 0) {
    countdownDates.forEach((element) => {
      const endTime = new Date(element.dataset.jsCountdown).getTime();

      timer({ element, endTime });
    });
  }
};

/*
 ** @param {Object} element - Element object
 ** @param {Number} endTime - Time to count down to as Unix timestamp
 */
const timer = ({ element, endTime }) => {
  let countdown = null;
  const daysElement = element.querySelector('[data-js-countdown-days]');
  const hoursElement = element.querySelector('[data-js-countdown-hours]');
  const minutesElement = element.querySelector('[data-js-countdown-minutes]');
  const secondsElement = element.querySelector('[data-js-countdown-seconds]');

  // Run every second until expired
  countdown = setInterval(() => {
    const now = new Date().getTime();
    const secondsLeft = Math.round((endTime - now) / 1000);

    if(secondsLeft <= -1) {
      clearInterval(countdown);
      return;
    }

    displayTimeLeft(secondsLeft, daysElement, hoursElement, minutesElement, secondsElement);

  }, 1000);

  return countdown;
}

/*
 ** @param {Object} element - Element object
 ** @param {Number} count - Seconds to count down
 */
const displayTimeLeft = (count, days, hours, minutes, seconds) => {
  if (typeof days !== 'undefined') {
    days.innerText = pad(Math.floor(count / 86400), 2);
  }
  if (typeof hours !== 'undefined') {
    hours.innerText = pad(Math.floor((count % 86400) / 3600), 2);
  }
  if (typeof minutes !== 'undefined') {
    minutes.innerText = pad(Math.floor((count % 86400) % 3600 / 60), 2);
  }
  if (typeof seconds !== 'undefined') {
    seconds.innerText = pad(Math.floor(count % 60), 2);
  }
}

/*
 ** @param {Number} padNumber - Number to be padded
 ** @param {Number} targetLength - Length of the resulting string
 ** @param {String} padString - String to pad the current string with
 */
const pad = (padNumber, targetLength, padString) => {
  padString = padString || '0';
  padNumber = padNumber + '';
  return padNumber.length >= targetLength ? padNumber : new Array(targetLength - padNumber.length + 1).join(padString) + padNumber;
}

initCount();
