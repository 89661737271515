<template>
  <div
    :id="group.slug"
    class="absolute z-50 md:z-auto inset-0 md:inset-auto md:top-full md:inset-x-0 bg-color-white border border-color-base-keyline md:-mx-1px w-bleed">
    <div class="h-full overflow-hidden py-40 md:p-0">
      <div class="h-full overflow-auto md:overflow-visible px-40 md:p-0">
        <header class="flex md:hidden justify-between items-center mb-6">
          <h3 class="font-heading-serif-italic text-18 text-color-grey-mid m-0">Filter by</h3>
          <button class="flex items-center bg-color-transparent p-10 -mr-10"
                  @click="closeFilterGroup">
            <span class="visually-hidden">Close</span>
            <svg class="block flex-shrink-0 w-20 h-4 sm:ml-10"
                focusable="false"
                role="presentation">
              <use xlink:href="#sprite-icon-cross" />
            </svg>
          </button>
        </header>

        <ul class="flex flex-wrap list-none p-0 md:px-40 md:pt-30 my-0 md:-mx-4">

          <ListFiltersFilter
            v-for="(filter, index) in group.items"
            :filter="filter"
            :selected-filters="selectedFilters"
            :key="index"
            @select-filter="selectFilter"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import ListFiltersFilter from './ListFiltersFilter.vue';

export default {
  name: 'ListFiltersDropdown',
  components: {
    ListFiltersFilter
  },
  props: {
    group: {
      type: Object,
      required: true
    },
    selectedFilters: {
      type: Array,
      default() {
        return []
      }
    },
  },
  methods: {

    /**
     * closeFilterGroup
     *
     * Inform parent component to close filter group
     */
    closeFilterGroup() {
      this.$emit('close-filter-group');
    },

    /**
     * selectFilterGroup
     *
     * Inform parent component to select filter group
     *
     * @param {String} filterGroup
     */
    selectFilter(filter) {
      this.$emit('select-filter', this.group.slug, filter );
    },
  }
};
</script>
