import Vue from 'vue';
import HeaderSearch from './components/HeaderSearch.vue';

const $headerSearchPlaceholder = document.querySelector('.js-header-search');

const HeaderSearchRoot = Vue.extend({
  props: ['actionUrl', 'initialSearchQuery'],
});

if ($headerSearchPlaceholder) {
  new HeaderSearchRoot({
    name: 'HeaderSearch',
    propsData: {
      actionUrl: $headerSearchPlaceholder.dataset.action,
      initialSearchQuery: $headerSearchPlaceholder.dataset.searchQuery,
    },
    render: h => h(HeaderSearch),
  }).$mount($headerSearchPlaceholder);
}
