<template>
  <p v-if="loading" class="container relative px-20 mx-auto md:px-40 lg:px-50">
    <span class="sr-only">Loading</span>
    <svg class="block mx-auto" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="64px" height="64px" viewBox="0 0 128 128" xml:space="preserve"><g><path d="M64 9.75A54.25 54.25 0 0 0 9.75 64H0a64 64 0 0 1 128 0h-9.75A54.25 54.25 0 0 0 64 9.75z" fill="#000"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1400ms" repeatCount="indefinite"></animateTransform></g></svg>
  </p>
</template>

<script>
  export default {
    name: 'LoadingIcon',
    props: {
      loading: {
        type: Boolean,
        required: true
      }
    },
  }
</script>