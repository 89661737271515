/*
 * Sample accordion - Adding aria attributes
 * Dependencies: a11y-toggle
 */

import $ from 'jquery';
import { breakpoints } from '../../core/breakpoints';
import { ariaToggle } from 'numiko/a11y-toggle/a11y-toggle';

const $accordions = $('.js-accordion-btn');

/**
 * The function that runs on click of an accordion
 * button. Handles opening and closing of the accordion.
 *
 * @param {Object} e - the event object that triggered the click.
 * @returns {Void}
 */
function attachAccordionHandler(e) {
    e.preventDefault();

    const $this = $(e.currentTarget);
    const $accordionContent = $this
      .closest('.js-accordion')
      .find('.js-accordion-content');

    $this
      .toggleClass('is-active')
      .find('svg')
      .toggleClass('hidden');

    $accordionContent.stop().slideToggle(() => {
      ariaToggle($this, 'aria-expanded', $accordionContent.is(':visible'));
      ariaToggle(
        $accordionContent,
        'aria-hidden',
        $accordionContent.is(':hidden')
      );
    });
}

/**
 * Destroys an accordion item. Resets the aria state; removes
 * event listeners; resets the icon if applicable; shows the accordion
 * content.
 *
 * @param {Object} $accordionItem - The <button></button> that triggers an accordion
 * to open.
 * @returns {Void}
 */
function destroyAccordion($accordionItem) {
  const $accordionContent = $accordionItem
    .closest('.js-accordion')
    .find('.js-accordion-content');

  // Revert the icons back to their initial state.
  if($accordionItem.hasClass('is-active')) {
    $accordionItem
    .removeClass('is-active')
    .find('svg')
    .toggleClass('hidden');
  }

  // Show the accordion content.
  $accordionContent.removeAttr('style');

  // Reset aria state for button and accordion content.
  ariaToggle($accordionItem, 'aria-expanded', false);
  ariaToggle(
    $accordionContent,
    'aria-hidden',
    false
  );

  // Remove event listener.
  $accordionItem.off('click')
}

/**
 * Initialises the click handlers on accordion items and
 * sets the breakpoint at which each accordion can be disabled, if applicable.
 *
 * @param {jQuery} $accordions - A jQuery list of all accordion items on the page.
 * to open.
 * @returns {Void}
 */
function initAccordions($accordions) {
  $accordions.each(function(){
    const mediaQueryToDisableAccordionAt = $(this).data('js-accordion-disable-at');
    const $this = $(this);

    // If an accordion has a media query rule, initialise a breakpoint check.
    if(mediaQueryToDisableAccordionAt) {
      breakpoints.check({
        breakpoint: mediaQueryToDisableAccordionAt,
        match: function(){
          destroyAccordion($this);
        },
        unmatch: function() {
          $this.on('click', attachAccordionHandler)
        }
      })
    } else {
      // Initialise the click handler on the accordion item.
      $this.on('click', attachAccordionHandler)
    }
  })
}

/*
 * Initialise the accordions.
*/
initAccordions($accordions);


