<template>
  <div v-if="totalDonationAmount" class="my-40">
    <div v-if="appType =='donation' ">
      <h2 class="max-w-2xl font-heading-sans-serif text-40 lg:text-56 leading-heading text-color-themed-primary mb-20">
        Add Gift Aid to your donation
      </h2>

      <p class="max-w-2xl font-body-serif text-24 leading-snug mb-30 md:mb-50">
        With Gift Aid, the British Academy can increase the value of your donation by 25% at no extra cost to you.
      </p>
    </div>

    <div v-if="appType =='subscription' ">
      <h2 class="max-w-2xl font-heading-sans-serif text-40 lg:text-56 leading-heading text-color-themed-primary mb-20">
        Add Gift Aid to your subscription
      </h2>

      <p class="max-w-2xl font-body-serif text-24 leading-snug mb-30 md:mb-50">
        With Gift Aid, the British Academy can increase the value of your subscription and any optional donation by 25% at no extra cost to you.
      </p>
    </div>

    <div class="flex items-center my-30">
      <span class="font-heading-serif-italic text-30 md:text-80 leading-heading">
        {{ formatCurrencyString(totalDonationAmount) }}
      </span>

      <svg class="w-30 h-30 flex-shrink-0 text-color-themed-primary mx-20 lg:w-40 lg:h-40" aria-hidden="true" focusable="false">
        <use xlink:href="#sprite-icon-right-arrow" />
      </svg>

      <span class="font-heading-serif-italic text-30 md:text-80 leading-heading">
        {{ formatCurrencyString(donationAmountWithGiftAid) }}
      </span>
    </div>

    <div class="max-w-xl">
      <InputCheckbox
        v-model="giftAidSelectedModel"
        v-bind="inputProps"
      />
    </div>
  </div>
</template>

<script>

import InputCheckbox from './InputCheckbox.vue';

export default {
  components: {
    InputCheckbox
  },
  inject: ['appType'],
  props: {
    isGiftAidSelected: {
      type: Boolean,
      required: true,
    },
    totalDonationAmount: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      inputProps: {
        label: `
          I confirm that I am a UK taxpayer and understand that if I pay less
          income tax/or Capital Gains Tax than the amount of Gift Aid claimed on
          all my donations and membership subscriptions in that tax year it is my
          responsibility to pay the difference. I understand that other taxes such
          as VAT and Council Tax do not qualify. I understand that the charity
          will reclaim 25p of every £1 that I give henceforth.
        `,
        id: 'id_giftaid',
        inputAttributes: {
          name: 'id_giftaid',
        }
      },
    }
  },
  computed: {
    /**
     * @returns {Number}
     */
    donationAmountWithGiftAid() {
      return this.totalDonationAmount * 1.25; // add 25% to donation
    },
    giftAidSelectedModel: {
      get() {
        return this.isGiftAidSelected;
      },
      set(value) {
        this.$emit('set:isGiftAidSelected', value);
      },
    }
  },
  methods: {
    /**
     * formatCurrencyString
     *
     * @param {Number} - currencyValue
     * @returns {String}
     */
    formatCurrencyString(currencyValue) {
      let minimumFractionDigits = 2;
      let maximumFractionDigits = 2;

      if(Number.isInteger(currencyValue)) {
        minimumFractionDigits = 0;
        maximumFractionDigits = 0;
      }

      return Number(currencyValue).toLocaleString('en', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits,
        maximumFractionDigits,
      });
    }
  },
}
</script>
