/* All Tableau embeds on v3 and later use a web-component to load an iframe. */
const tableauVizEmbed = document.querySelector('tableau-viz');

/**
 * Initializes the Tableau API dynamically if an embed exists on the page.
 * Documentation: {@link https://help.tableau.com/current/api/embedding_api/en-us/index.html }
 * @function initTableauViz
 * @returns {void}
 */
const initTableauViz = () => {
  if (tableauVizEmbed) {
    const script = document.createElement('script');

    /* The CDN needs a version number to be specified. */
    script.src = 'https://embedding.tableauusercontent.com/tableau.embedding.3.8.0.min.js';
    script.type = 'module';
    script.async = true;

    document.head.appendChild(script);
  }
};
initTableauViz();
