<template>
  <form @submit.prevent="formStep1Submit">
    <div class="flex flex-wrap max-w-xl my-40">
      <label
        class="block flex-shrink-0 text-22 md:text-24  mr-2"
        for="id_donation_amount"
      >
        I would like to make a subscription payment of
      </label>

    </div>

    <PaymentAmountButtons
      :donation-amounts="donationOption.defaultDonationAmounts"
      :donation-amounts-description="donationOption.defaultDonationAmountsDescription"
      :total-donation-amount="totalDonationAmount"
      @set:totalDonationAmount="setDonationTotal"
    />

    <slot name="additional-content-after" />

    <div class='flex flex-col flex-nowrap sm:flex-row mt-6'>
      <button
        type="submit"
        class="inline-block cursor-pointer whitespace-nowrap font-founders-grotesk font-semibold text-18 text-color-white bg-color-themed-primary hover:text-color-white  hover:bg-color-black px-40 py-20 tabbing-focus:shadow-focus"
        :disabled="totalDonationAmount == ''"
      >
        Proceed
      </button>
    </div>
  </form>

</template>

<script>
import PaymentOptionMixin from '../mixins/PaymentOptionMixin.js';

export default {
  name: "PaymentOptionSubscribeDD",
  mixins: [PaymentOptionMixin],
  props: {
    optionalDonation: {
      type: [Number, String],
      required: false
    }
  },
  watch: {
    donationOption: {
      handler(current, prev) {
        if(current.key !== prev.key) {
          this.setDonationTotal('');
        }
      },
      deep: true
    },
  },
  mounted() {
    this.setDonationType(this.donationOption.key);
    this.$emit('set:recurringPaymentCount', null);

    this.setDonationTotal('');
  },
  updated() {
    this.setDonationType(this.donationOption.key);
  },
}
</script>
