var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.formStep1Submit.apply(null, arguments)}}},[_c('p',{staticClass:"font-heading-sans-serif font-bold text-30"},[_vm._v("Please select an amount to give:")]),_vm._v(" "),_c('PaymentAmountButtons',{attrs:{"donation-amounts":_vm.donationOption.defaultDonationAmounts,"total-donation-amount":_vm.totalDonationAmount},on:{"set:totalDonationAmount":_vm.setDonationTotal}}),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap max-w-2xl my-40"},[_c('label',{staticClass:"block text-22 md:text-24 mr-2",attrs:{"for":"id_amount"}},[_vm._v("\n      Or choose an alternative amount\n    ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap items-center w-full md:w-auto"},[_c('span',{staticClass:"text-22 md:text-24 mr-2"},[_vm._v("£")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.donationAmountModel),expression:"donationAmountModel",modifiers:{"number":true}}],staticClass:"no-spinner flex-grow w-full max-w-200 md:w-donate-input font-body-serif text-22 md:text-24 placeholder-color-ba-stone-300 bg-color-transparent border-b border-color-base rounded-none p-0 focus:outline-none tabbing-focus:shadow-focus",attrs:{"type":"number","name":"amount","step":"1","placeholder":"Enter amount","max":"100000","min":"1","required":"","id":"id_amount"},domProps:{"value":(_vm.donationAmountModel)},on:{"input":function($event){if($event.target.composing)return;_vm.donationAmountModel=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}})])]),_vm._v(" "),_c('div',{staticClass:"my-40"},[_c('InputSelect',{attrs:{"label":"Please select a payment period","id":"id_payment_period","default-option":"Select a payment period","input-attributes":{
        required: 'required',
      },"options":_vm.paymentPeriodOptions},scopedSlots:_vm._u([{key:"label",fn:function({ id, label }){return [_c('label',{staticClass:"font-heading-sans-serif font-bold text-24 mb-4",attrs:{"for":id}},[_vm._v("\n          "+_vm._s(label)+"\n        ")])]}}]),model:{value:(_vm.paymentPeriodInYears),callback:function ($$v) {_vm.paymentPeriodInYears=$$v},expression:"paymentPeriodInYears"}}),_vm._v(" "),_c('InputSelect',{attrs:{"label":"Please select a payment schedule","id":"id_payment_shedule","default-option":"Select a payment schedule","input-attributes":{
        required: 'required',
      },"options":[
        {
          key: 'monthly',
          label: 'Monthly',
        },
        {
          key: 'yearly',
          label: 'Annually',
        },
      ]},on:{"input":_vm.setDonationType},scopedSlots:_vm._u([{key:"label",fn:function({ id, label }){return [_c('label',{staticClass:"font-heading-sans-serif font-bold text-24 mb-4",attrs:{"for":id}},[_vm._v("\n          "+_vm._s(label)+"\n        ")])]}}]),model:{value:(_vm.paymentSchedule),callback:function ($$v) {_vm.paymentSchedule=$$v},expression:"paymentSchedule"}})],1),_vm._v(" "),(_vm.transactionAmount)?_c('div',{staticClass:"max-w-2xl mb-40 text-center bg-color-grey-faint p-30"},[_c('h3',{staticClass:"mb-20"},[_vm._v("You have selected:")]),_vm._v(" "),_c('div',{staticClass:"font-heading-serif-italic text-30 md:text-80 leading-heading mb-30"},[_vm._v("\n      £"+_vm._s(_vm.transactionAmount.toFixed(2))+"\n    ")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.paymentScheduleDetailText))])]):_vm._e(),_vm._v(" "),_vm._t("additional-content-after"),_vm._v(" "),_vm._m(0)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col flex-nowrap sm:flex-row"},[_c('button',{staticClass:"inline-block cursor-pointer whitespace-nowrap font-founders-grotesk font-semibold text-18 text-color-white hover:text-color-white bg-color-themed-primary hover:bg-color-black px-40 py-20 tabbing-focus:shadow-focus",attrs:{"type":"submit"}},[_vm._v("\n      Proceed\n    ")])])
}]

export { render, staticRenderFns }