<template>
  <div id="summary">
      <!-- Subscription app payments summary heading -->
      <h2 v-if="appType =='subscription'" class="max-w-2xl font-heading-sans-serif text-40 lg:text-56 leading-heading text-color-themed-primary mb-30 md:mb-50">
        Subscription summary
      </h2>
      <!-- Donation app payments summary heading -->
      <h2 v-if="appType =='donation'" class="max-w-2xl font-heading-sans-serif text-40 lg:text-56 leading-heading text-color-themed-primary mb-30 md:mb-50">
        Donation summary
      </h2>

      

      <div class="max-w-2xl">
        <h3 class="font-body-sans-serif text-16 text-color-grey-mid mb-20">Your details</h3>

        <p class="font-body-serif text-24 leading-tighter mb-30 md:mb-50">
          <template v-if="serverData.title">
            {{ serverData.title }}.
          </template>

          {{ fullName }} <br />
          {{ serverData.address_1 }} <br />

          <template v-if="serverData.address_2">
            {{ serverData.address_2 }} <br />
          </template>

          {{ serverData.town_city }} <br />
          {{ serverData.county }} <br />
          {{ serverData.postcode }} <br /><br />
          {{ serverData.email_address }}
        </p>

        <!-- Subscription app payments -->
        <div v-if="appType =='subscription' ">
          <h3 class="font-body-sans-serif text-16 text-color-grey-mid mb-20">
            Your subscription amount
          </h3>

          <p class="font-body-serif text-24 leading-snug mb-40 md:mb-60">
            &pound;{{ totalDonationAmount }} {{ getFormattedPaymentTransactionDescription('subscription') }}
          </p>

          <h3 class="font-body-sans-serif text-16 text-color-grey-mid mb-20">
            Your donation amount
          </h3>

          <p class="font-body-serif text-24 leading-snug mb-40 md:mb-60">
            &pound;{{ optionalDonationAmount }} {{ getFormattedPaymentTransactionDescription('donation') }}
          </p>
        </div>

        <!-- Donation app payments -->
        <div v-if="appType =='donation' ">
          <h3 class="font-body-sans-serif text-16 text-color-grey-mid mb-20">
            Your donation amount
          </h3>

          <p class="font-body-serif text-24 leading-snug mb-40 md:mb-60">
            &pound;{{ formattedTransactionAmount }} {{ getFormattedPaymentTransactionDescription('donation') }}
          </p>
        </div>

      </div>

      <StripePayment
        v-if="isStripePayment"
        :server-data="serverData"
        :terms-accepted="termsAccepted"
        @formStepBack="formStepBack"
        @set:stripeTransactionId="(value) =>$emit('set:stripeTransactionId', value)"
        @set:termsAccepted="(value) => $emit('set:termsAccepted', value)"
        @set:serverValidationIssues="(value) => $emit('set:serverValidationIssues', value)"
      >
        <template v-slot:terms-and-conditions>
          <TermsAndConditions
            :terms-accepted="termsAccepted"
            :show-direct-debit-guarantee="isMonthlyDonation"
            @set:termsAccepted="(value) => $emit('set:termsAccepted', value)"
          />
        </template>
      </StripePayment>

      <GoCardlessPayment
        v-if="isGoCardlessPayment"
        :server-data="serverData"
        :terms-accepted="termsAccepted"
        @formStepBack="formStepBack"
        @set:termsAccepted="(value) => $emit('set:termsAccepted', value)"
        @set:serverValidationIssues="(value) => $emit('set:serverValidationIssues', value)"
       >
        <template v-slot:terms-and-conditions>
          <TermsAndConditions
            :terms-accepted="termsAccepted"
            :show-direct-debit-guarantee="isMonthlyDonation"
            @set:termsAccepted="(value) => $emit('set:termsAccepted', value)"
          />
        </template>
      </GoCardlessPayment>
  </div>
</template>

<script>
import GoCardlessPayment from './GoCardlessPayment.vue';
import StripePayment from './StripePayment.vue';
import InputCheckbox from './InputCheckbox.vue';
import TermsAndConditions from './TermsAndConditions.vue';

export default {
  components: {
    GoCardlessPayment,
    StripePayment,
    InputCheckbox,
    TermsAndConditions,
  },
  props: {
    termsAccepted: {
      type: Boolean,
      required: true
    },
    serverData: {
      type: Object,
      required: true,
    },
    totalDonationAmount: {
      type: Number,
      required: true
    },
    isMonthlyDonation: {
      type: Boolean,
      required: true,
    },
    isAnnualDonation: {
      type: Boolean,
      required: true,
    },
    isOneOffDonation: {
      type: Boolean,
      required: true,
    },
    transactionAmount: {
      type: Number,
      required: true
    },
    paymentScheduleDetailText: {
      type: String,
      required: true
    },
    optionalDonationAmount: {
      type: Number,
      required: true
    }
  },
  inject: ['appType'],
  data() {
    return {
      stripePaymentVerified: false,
    }
  },
  computed: {
    /**
     * One off payments are handled by Stripe
     *
     * @returns {Boolean}
     */
    isStripePayment() {
      return this.isOneOffDonation;
    },

    /**
     * GoCardless is used for recurring payment transactions
     *
     * @returns {Boolean}
     */
    isGoCardlessPayment() {
      return this.isMonthlyDonation || this.isAnnualDonation;
    },

    /**
     * fullName
     *
     * @returns {String}
     */
    fullName() {
      return `${this.serverData.first_name} ${this.serverData.last_name}`
    },

    /**
     * formattedTransactionAmount
     *
     * Transaction amount formatted to string with 2 decimal places for display purposes
     *
     * @returns {String}
     */
    formattedTransactionAmount() {
      if(Number.isInteger(this.transactionAmount)) {
        return this.transactionAmount;
      }

      return Number(this.transactionAmount).toFixed(2);
    },
  },
  methods: {
    formStepBack() {
      this.$emit('formStepBack');
    },
    /**
     * getFormattedPaymentTransactionDescription
     *
     * If we have a paymentScheduleDetailText show that, otherwise show the donation type
     * appended with paymentType, a label used to break down the scheduled cost.
     * 
     * A subscription, for example, can be broken down into a subscription fee payment
     * and a one-off donation payment with the paymentType distinguishing
     * between payment types in the payment summary.
     *
     * @param {String} paymentType label to append to formatted amount.
     * @returns {String}
     */
    getFormattedPaymentTransactionDescription(paymentType) {
      if(this.paymentScheduleDetailText) {
        return this.paymentScheduleDetailText;
      }

      return `${this.serverData.type} ${paymentType}`;
    },
  }
}

</script>
