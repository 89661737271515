/*
 * Numiko Breakpoints
 * Get breakpoints from shared configuration and add functionality for 'include media' (https://include-media.com/)
 * so that we can use the same style of syntax to *target breakpoints in sass and js.
 * USAGE: (see examples/breakpoints.js)
 */

const config = require('../config/config');
const breakpointsNested = config.breakpoints;

let setupCalled = false;

/*
 * Check if the breakpoint has an '='
 */
function isBreakpointInclusive(breakpoint) {
  var bpInclusive = breakpoint.substr(1, 1);
  if (bpInclusive == '=') {
    return true;
  } else {
    return false;
  }
}

/*
 * Check the operator to set whether its 'min' or 'max' for the media query
 */
function breakpointMinOrMax(breakpoint) {
  var bpOperator = breakpoint.substr(0, 1); // e.g. '<'
  if (bpOperator == '>') {
    return 'min';
  } else if (bpOperator == '<') {
    return 'max';
  } else {
    console.error(
      "Breakpoint condition didn't match either '>', '>=', '<=', <'"
    );
  }
}

/**
 * testChange
 *
 * Test whether breakpoints matches or not and call relevant functions
 * Utilises same methods as enquire js for deferSetup, setup, match, unmatch
 *
 * @param {Object} mql
 * @param {Object} obj
 * @param {Boolean} setupCalled
 */
function testChange(mql, obj) {
  if (mql.matches) {
    if (typeof obj.setup !== 'undefined' && setupCalled !== true) {
      obj.setup();
      setupCalled = true;
    }

    if (typeof obj.match !== 'undefined') {
      obj.match();
    }
  } else if (typeof obj.unmatch !== 'undefined') {
    obj.unmatch();
  }
}

/**
 * mediaQuerySetup
 *
 * Use Match Media to test whether breakpoint matches
 *
 * @param {String} breakpointMinMax
 * @param {Number} breakpointNumber
 * @param {Object} obj
 */
function mediaQuerySetup(breakpointMinMax, breakpointNumber, obj) {
  var mql = window.matchMedia(
    `(${breakpointMinMax}-width: ${breakpointNumber}px)`
  );

  if (typeof obj.setup !== 'undefined' && obj.deferSetup !== true) {
    // If deferSetup isn't set, call setup immediately
    obj.setup();
    setupCalled = true;
  }

  // Check breakpoints on page load
  testChange(mql, obj, setupCalled);

  // Listen for breakpoint changes
  mql.addListener(() => {
    testChange(mql, obj);
  });
}

var breakpoints = {
  check: function(obj) {
    var breakpoint = obj.breakpoint;
    var breakpointMinMax = breakpointMinOrMax(breakpoint);
    var breakpointInclusive = isBreakpointInclusive(breakpoint);
    var breakpointSanitized = breakpoint.replace(/<|>|=|px/g, ''); // Remove '<','>', '=' and 'px' e.g. 'large' or 1200
    var isBreakpointNumber = /^\d+$/.test(breakpointSanitized);
    var breakpointNumber;

    /*
     * Get the media query value - either directly from a number value or by a keyword match from the unison breakpoints
     */
    if (isBreakpointNumber) {
      breakpointNumber = parseInt(breakpointSanitized);
    } else {
      breakpointNumber = parseInt(breakpointsNested[breakpointSanitized]);
    }

    /*
     * Adjust media query value if its not inclusive
     */
    if (breakpointMinMax == 'min' && !breakpointInclusive) {
      breakpointNumber++;
    } else if (breakpointMinMax == 'max' && !breakpointInclusive) {
      breakpointNumber--;
    }

    mediaQuerySetup(breakpointMinMax, breakpointNumber, obj);
  },
};

export { breakpoints };
