<template>
  <button
    ref="button"
    type="submit"
    :disabled="isLoading"
    :style="buttonWidth ? `width: ${buttonWidth}px;` : ''"
    :class="{'pointer-events-none': isLoading}"
  >
    <div v-if="isLoading" class="w-20 h-20 m-auto border-2 border-t-transparent rounded-full animation-rotate-infinite" role="status">
      <span class="sr-only">Loading</span>
    </div>

    <template v-else>
      <slot />
    </template>
  </button>
</template>


<script>
import _debounce from 'lodash/debounce';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      buttonWidth: null,
    }
  },
  mounted() {
    this.getButtonWidth();
    this.addResizeListener();
  },
  beforeDestroy() {
    this.removeResizeListener();
  },
  methods: {
    /**
     * addResizeListener
     */
    addResizeListener() {
      window.addEventListener('resize', this.debouncedGetButtonWidth);
    },

    /**
     * removeEventListener
     */
    removeResizeListener() {
      window.removeEventListener('resize', this.debouncedGetButtonWidth);
    },

    /**
     * getButtonWidth
     */
    getButtonWidth() {
      if(!this.isLoading) {
        this.buttonWidth = null;

        this.$nextTick(() => {
          this.buttonWidth = this.$refs.button.offsetWidth;
        })
      }
    },

    /**
     * debouncedGetButtonWidth
     */
    debouncedGetButtonWidth: _debounce(function() {
      this.getButtonWidth();
    }, 200),
  }
};
</script>
