<template>
  <div v-if="ordering" class="md:w-56 relative md:ml-auto">
    <button class="relative z-1 flex justify-between items-center md:justify-center w-full h-full cursor-pointer font-heading-serif-italic text-18 text-color-grey-mid md:hover:text-color-white bg-color-white md:hover:bg-color-black border md:border-0 md:border-l border-color-base-keyline p-4 md:px-40 md:py-20 mt-10 md:m-0 tabbing-focus:shadow-focus-inset"
            @click.prevent="showSortByFilters"
            @focus="closeFilterGroup()"
    >Sort by
      <svg class="lg:block flex-shrink-0 w-2 h-2 ml-10"
          focusable="false"
          role="presentation">
        <use xlink:href="#sprite-icon-down-block-arrow" />
      </svg>
    </button>

    <transition name="slide-down-fade">
      <ul v-if="showSortFilter"
          class="absolute z-0 top-full inset-x-0 list-none p-0 m-0 -mr-1px">
        <li v-for="(value, key) in ordering"
            :key="key"
            class="relative hover:z-1"
            :class="[orderBy === value ? 'z-1' : 'z-0']">

          <button
            class="w-full cursor-pointer font-body-sans-serif text-15 text-color-black bg-color-white border hover:border-color-black-400 px-40 py-20 m-0 -mb-1px tabbing-focus:shadow-focus-inset"
            :class="[orderBy === value ? 'border-color-black' : 'border-color-base-keyline']"
            @click.prevent="changeOrderBy(value)"
          >
            {{ key }}
          </button>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import config from '../../../config/config';

export default {
  name: 'ListFiltersSorting',
  props: {
    ordering: {
      type: Object,
      required: true
    },
    orderBy: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showSortFilter: false,
    }
  },
  methods: {
    showSortByFilters() {
      this.closeFilterGroup();
      this.showSortFilter = !this.showSortFilter
    },
    changeOrderBy(orderType) {
      this.closeFilterGroup();
      this.$emit('change-order-by', orderType);
      this.showSortFilter = false;
    },
    closeFilterGroup() {
      this.$emit('close-filter-group');
    },
  }
};
</script>
