<template>

  <div id="donation">

    <h2 class="max-w-2xl font-heading-sans-serif text-40 lg:text-56 leading-heading text-color-themed-primary mb-40 last:mb-0">
      Pay subscription online
    </h2>

    <div class="max-w-xl font-body-sans-serif text-16 text-color-grey-mid mb-30" >
      <p>Fellows pay an annual subscription of £275 in relation to their Fellowship of the Academy. Subscription becomes due for serving Fellows at the beginning of each calendar year.</p>
      <p>Fellows' subscriptions are reduced to half from the January following their 65th birthday. From the January following their 70th birthday Fellows are no longer required to pay a subscription, though many choose to make a donation instead.</p>
      <p>The subscription rate is reviewed annually. If your payment amount changes, either because the subscription amount changes or because you have reached the age of 65 or 70, we will contact you.</p>
      <p><strong>Only UK residents are able to make regular payments through this page.</strong></p>
    </div>

    <div class="flex flex-col mb-6 -mx-3 lg:flex-row lg:flex-wrap ">
      <button
        v-for="donationOption in enabledDonationOptionsData"
        :key="donationOption.key"
        class="cursor-pointer text-center block font-founders-grotesk font-semibold text-18 border border-color-base-keyline py-4 px-30 mx-3 mb-10 [ hover:border-color-themed-primary tabbing-focus:shadow-focus ][ lg:my-0 ]"
        :class="selectedDonationOptionKey === donationOption.key ? 'bg-color-themed-primary text-color-white' : 'text-color-black'"
        @click="() => $emit('set:selectedDonationOptionKey', donationOption.key)"
      >
        {{ donationOption.buttonText }}
      </button>
    </div>

    <!-- After selecting donation option -->
    <component
      v-if="selectedDonationOptionKey"
      :is="donationComponent"
      :donation-option="selectedDonationOption"
      :total-donation-amount="totalDonationAmount"
      :optional-donation="optionalDonation"
      :transaction-amount="transactionAmount"
      :is-gift-aid-selected="isGiftAidSelected"
      :is-monthly-donation="isMonthlyDonation"
      :is-annual-donation="isAnnualDonation"
      @set:recurringPaymentCount="setRecurringPaymentCount"
      @set:totalDonationAmount="(value) => $emit('set:totalDonationAmount', value)"
      @set:donationType="(value) => $emit('set:donationType', value)"
      @set:isGiftAidSelected="(value) => $emit('set:isGiftAidSelected', value)"
      @set:paymentScheduleDetailText="(value) => $emit('set:paymentScheduleDetailText', value)"
      @formStepForward="formStepForward"
    >
      <template v-slot:additional-content-after>

        <div class="my-6">

          <label
            for="optional_donation"
            class="block text-22 md:text-24">I would also like to add a donation to my payment (optional): </label>

          <div>
            <span class='text-22 md:text-24 mr-2'>&pound;</span>
            <input
              v-model.number="optionalDonation"
              @change="$emit('set:optionalDonation', optionalDonation);"
              @keypress="digitsOnly($event)"
              type="number"
              pattern="[0-9]"
              name="optional_donation"
              step="1"
              placeholder="Enter amount"
              max="100000"
              min="0"
              id="optional_donation"
              class="no-spinner flex-grow w-full max-w-200 md:w-donate-input font-body-serif text-22 md:text-24 placeholder-color-themed-primary-300 bg-color-transparent border-b border-color-base rounded-none p-0 focus:outline-none tabbing-focus:shadow-focus"
            />
          </div>

        </div>

         <GiftAid
          :total-donation-amount="totalDonationAmount + optionalDonationAmount"
          :is-gift-aid-selected="isGiftAidSelected"
          @set:isGiftAidSelected="(value) => $emit('set:isGiftAidSelected', value)"
        />

      </template>
    </component>


  </div>
</template>

<script>

import PaymentOptionSubscribeRecurring from './PaymentOptionSubscribeRecurring.vue';
import PaymentOptionSubscribeSimple from './PaymentOptionSubscribeSimple.vue';
import InputSelect from './InputSelect.vue';
import GiftAid from './GiftAid.vue';

import PaymentStep1Mixin from '../mixins/PaymentStep1Mixin.js';

/**
 * Donation options configure donation option information required for the server and which
 * donation component to render
 *
 * @typedef {Object} DonationOption
 * @property {String} key - Option that is sent to the server
 * @property {String} label - Human readable value to display as terminology can differ from the key sent to the server
 * @property {Array<Number>} defaultDonationAmounts - Default amounts that can be selected by the user
 * @property {String} component - Which component is used to render donationOption
 * @property {Boolean} hasCount
 */


export default {
  name: "PaymentStep1Subscription",
  components: {
    PaymentOptionSubscribeRecurring,
    PaymentOptionSubscribeSimple,
    InputSelect,
    GiftAid,
  },
  mixins: [PaymentStep1Mixin],
  data() {
    return {
      donationOptions: [
        {
          key: 'yearly',
          label: 'annual',
          buttonText: 'Pay my annual subscription via direct debit',
          defaultDonationAmounts: [285, 142.50],
          defaultDonationAmountsDescription: ["Full subscription", "For Fellows over 65"],
          component: 'PaymentOptionSubscribeRecurring',
          hasCount: false,
        },
        {
          key: 'one-off',
          label: 'one-off',
          buttonText: 'Make a single payment',
          defaultDonationAmounts: [285, 142.50],
          defaultDonationAmountsDescription: ["Full subscription", "For Fellows over 65"],
          component: 'PaymentOptionSubscribeSimple',
          hasCount: false,
        },
      ],
      optionalDonation: ""
    }
  },
  computed: {
     transactionAmount() {

      if(this.totalDonationAmount) {
        //this.optionalDonation is undefined default as we want the 2-way binding to not show 0 in the input on load.
        const optionalDonation =  this.optionalDonation ? this.optionalDonation : 0;
        return parseInt(this.totalDonationAmount + optionalDonation);
      }

      return 0;
    },
  },
  methods: {
    digitsOnly(e){
      const charCode = e.which ? e.which : e.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        e.preventDefault();
      }
      // this.$emit('set:optionalDonation', this.optionalDonation);
    }
  }
}
</script>
