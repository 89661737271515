<template>
  <form @submit.prevent="formStep1Submit">

    <p class="font-heading-sans-serif font-bold text-30">Please select an amount to give:</p>

    <PaymentAmountButtons
      :donation-amounts="donationOption.defaultDonationAmounts"
      :total-donation-amount="totalDonationAmount"
      @set:totalDonationAmount="setDonationTotal"
    />

    <div class="flex flex-wrap max-w-2xl my-40">
      <label
        class="block text-22 md:text-24  mr-2"
        for="id_amount"
      >
        Or choose an alternative amount
      </label>

      <div class="flex flex-wrap items-center w-full md:w-auto">
        <span class='text-22 md:text-24 mr-2'>&pound;</span>

        <input
          v-model.number="donationAmountModel"
          type="number"
          name="amount"
          step="1"
          placeholder="Enter amount"
          max="100000"
          min="1"
          required
          id="id_amount"
          class="no-spinner flex-grow w-full max-w-200 md:w-donate-input font-body-serif text-22 md:text-24 placeholder-color-ba-stone-300 bg-color-transparent border-b border-color-base rounded-none p-0 focus:outline-none tabbing-focus:shadow-focus"
        />
      </div>
    </div>

    <div class="my-40">
      <InputSelect
        v-model="paymentPeriodInYears"
        label="Please select a payment period"
        id="id_payment_period"
        default-option="Select a payment period"
        :input-attributes="{
          required: 'required',
        }"
        :options="paymentPeriodOptions"
      >
        <template v-slot:label="{ id, label }">
          <label :for="id" class="font-heading-sans-serif font-bold text-24 mb-4">
            {{ label }}
          </label>
        </template>
      </InputSelect>

      <InputSelect
        v-model="paymentSchedule"
        label="Please select a payment schedule"
        id="id_payment_shedule"
        default-option="Select a payment schedule"
        :input-attributes="{
          required: 'required',
        }"
        @input="setDonationType"
        :options="[
          {
            key: 'monthly',
            label: 'Monthly',
          },
          {
            key: 'yearly',
            label: 'Annually',
          },
        ]"
      >
        <template v-slot:label="{ id, label }">
          <label :for="id" class="font-heading-sans-serif font-bold text-24 mb-4">
            {{ label }}
          </label>
        </template>
      </InputSelect>
    </div>

    <div
      v-if="transactionAmount"
      class="max-w-2xl mb-40 text-center bg-color-grey-faint p-30"
    >

      <h3 class="mb-20">You have selected:</h3>

      <div class="font-heading-serif-italic text-30 md:text-80 leading-heading mb-30">
        &pound;{{ transactionAmount.toFixed(2) }}
      </div>

      <p>{{ paymentScheduleDetailText }}</p>

    </div>

    <slot name="additional-content-after" />

    <div class='flex flex-col flex-nowrap sm:flex-row'>
      <button
        type="submit"
        class="inline-block cursor-pointer whitespace-nowrap font-founders-grotesk font-semibold text-18 text-color-white hover:text-color-white bg-color-themed-primary hover:bg-color-black px-40 py-20 tabbing-focus:shadow-focus"
      >
        Proceed
      </button>
    </div>
  </form>
</template>

<script>
import PaymentOptionMixin from '../mixins/PaymentOptionMixin.js';

export default {
  mixins: [PaymentOptionMixin],
  props: {
    transactionAmount: {
      type: Number,
      required: true
    },
    isMonthlyDonation: {
      type: Boolean,
      required: true,
    },
    isAnnualDonation: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      paymentPeriodOptions: [
        {
          key: '1',
          label: 'Over one year',
          panelText: 'one year',
        },
        {
          key: '2',
          label: 'Over two years',
          panelText: 'two years',
        },
        {
          key: '3',
          label: 'Over three years',
          panelText: 'three years',
        },
        {
          key: '4',
          label: 'Over four years',
          panelText: 'four years',
        },
      ],
      donationType: [
        {
          key: 'monthly',
          label: 'Monthly',
          panelText: 'month',
        },
        {
          key: 'yearly',
          label: 'Annually',
          panelText: 'year'
        },
      ],
      paymentPeriodInYears: '',
      paymentSchedule: '',

    }
  },
  computed: {
    paymentScheduleDetailText() {
      let details = '';

      const paymentScheduleText = this.getValueFromFormOption({
        formOptions: this.donationType,
        key: this.paymentSchedule,
        value: 'panelText',
      });

      const paymentPeriodText = this.getValueFromFormOption({
        formOptions: this.paymentPeriodOptions,
        key: this.paymentPeriodInYears,
        value: 'panelText',
      });

      if(paymentScheduleText && paymentPeriodText) {
        details = `Per ${paymentScheduleText} for ${paymentPeriodText}`;
      }

      return details;
    },
    totalPaymentsCount() {
      if(this.isAnnualDonation) {
        return Number(this.paymentPeriodInYears);
      }

      if(this.isMonthlyDonation) {
        return this.paymentPeriodInYears * 12;
      }

      return 0;
    },
  },
  watch: {
    totalPaymentsCount(value) {
      this.$emit('set:recurringPaymentCount', value);
    },
    paymentScheduleDetailText(value) {
      this.$emit('set:paymentScheduleDetailText', value);
    },
  },
  mounted() {
    this.setDonationType('');
    this.setDonationTotal('');
  },
  methods: {
    getValueFromFormOption({ formOptions, key, value }) {
      const optionObject = formOptions.find(option => {
        return key === option.key
      });

      if(optionObject) {
        return optionObject[value];
      }
    },
  }
}
</script>
