import $ from 'jquery';
import { ariaToggle } from 'numiko/a11y-toggle/a11y-toggle';

/**
 *
 * @param {jQuery Object} $button - podcast item button selector
 * @param {jQuery Object} $content - podcast item content selector
 */
function setAria($button, $content) {
  ariaToggle($button, 'aria-expanded', $content.is(':visible'));
  ariaToggle($content, 'aria-hidden', $content.is(':hidden'));
}

$('.js-podcast').on('click', '.js-podcast-btn', e => {
  e.preventDefault();
  const $selectedButton = $(e.currentTarget);
  const $buttonIcon = $($selectedButton).find('.js-podcast-btn-icon');
  const $podcastContent = $selectedButton
    .closest('.js-podcast')
    .find('.js-podcast-content');

  $buttonIcon.toggleClass('rotate-90 rotate-270');

  $podcastContent.stop().slideToggle(() => {
    setAria($selectedButton, $podcastContent);
  });
});
