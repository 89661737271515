<template>

  <form @submit.prevent="submitForm">

    <slot name="terms-and-conditions" />

    <div class="flex flex-col flex-nowrap sm:flex-row -mx-3 mt-40">
      <button
        type="button"
        class="flex-1 inline-block cursor-pointer whitespace-nowrap font-founders-grotesk font-semibold text-18 border border-color-base-keyline hover:border-color-ba-stone py-4 px-50 mx-3 my-10 sm:my-0 tabbing-focus:shadow-focus sm:flex-none"
        @click="formStepBack"
      >
        Back
      </button>

      <ButtonLoading
        type="submit"
        class="flex-1 inline-block cursor-pointer whitespace-nowrap font-founders-grotesk font-semibold text-18 text-color-white hover:text-color-white bg-color-themed-primary hover:bg-color-black py-4 px-50 mx-3 my-10 sm:my-0 tabbing-focus:shadow-focus sm:flex-none"
        :is-loading="isSubmitting"
      >
        Proceed to payment
      </ButtonLoading>
    </div>

  </form>

</template>

<script>

import ButtonLoading from "./ButtonLoading.vue";
import { fetchWrapper } from '../api/fetcher';

export default {
  components: {
    ButtonLoading,
  },
  inject: ['csrfToken', 'endpoints'],
  props: {
    serverData: {
      type: Object,
      required: true
    },
    termsAccepted: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      isSubmitting: false,
    }
  },
  methods: {
    /**
     * submitForm
     *
     * Post data to `gocardless.start` endpoint. On success the user will be
     * sent to GoCardless to complete the transaction. Go Cardless will then
     * return the user to the thank you page
     */
    submitForm() {
      this.isSubmitting = true;

      fetchWrapper.post(
        this.endpoints.gocardless.start,
        this.serverData,
        this.csrfToken
      )
      .then((data) => {
        if(data.redirect) {
          window.location.href = data.redirect;
        }
      })
      .catch((error) => {
        this.isSubmitting = false;

        if(error.response.status === 422) {

          error.response.json().then(serverValidationIssues => {
            this.setServerValidation(serverValidationIssues.error);
          })

        } else {
          console.error(error.statusText);
        }
      })
    },

    setServerValidation(serverValidationIssues) {
      this.formStepBack();
      this.$emit('set:serverValidationIssues', serverValidationIssues);
    },

    formStepBack() {
      this.$emit('formStepBack');
    },

  },
}

</script>
