import Vue from 'vue';
import VueMeta from 'vue-meta';
import FilteredList from './components/FilteredList.vue';

/**
 * The HTML element to which we attach our
 * Vue instance to.
 * @type {HTMLElement}
 */
const $listingPlaceholder = document.querySelector('.vue-filtered-list');

if ($listingPlaceholder) {
  /**
   * The API endpoint to which Wagtail will return our results.
   * @type {String}
   */
  const apiUrl = $listingPlaceholder.dataset.listingApiUrl;

  /**
   * Whether to hide the filters (ListFilters) within the Vue app.
   * @type {Boolean}
   */
  const hideFilter = $listingPlaceholder.dataset.hideFilter === 'true';

  // Optionally pass parent page id to limit results to descendants of the page.
  const descendantOf =
    parseInt($listingPlaceholder.dataset.descendantOf) || null;

  /**
   * Initial order for listing, specified in the CMS on book listing pages
   * This will be overridden if there is a user specified order when a filter
   * is set on the query string
   */
  const defaultOrderBy = $listingPlaceholder.dataset.defaultOrderBy;

  /**
   * The display type for the listing. Used to determine how a listing type
   * should display.
   */
  const listingDisplay = $listingPlaceholder.dataset.listingDisplay || 'default';

  Vue.use(VueMeta);

  new Vue({
    name: 'FilteredListRoot',
    render: h =>
      h(FilteredList, {
        props: {
          apiUrl,
          descendantOf,
          hideFilter,
          defaultOrderBy,
          listingDisplay,
        },
      }),
  }).$mount($listingPlaceholder);
}
