const newsletterSignup = document.querySelector('[data-js-newsletter-signup]');
const newsletterSuccessMessage = document.querySelector(
  '[data-js-newsletter-success]'
);
const newsletterSignupFormElement = document.querySelector('#signup');

/**
 * Scroll user to thank you message after form has submitted and thank you message is displayed
 */
if (newsletterSuccessMessage) {
  // Wait for DOMContentLoaded event, otherwise the user will be taken back to the top of the page
  document.addEventListener('DOMContentLoaded', function() {
    newsletterSignup.scrollIntoView({
      alignToTop: false,
      behavior: 'smooth',
    });
  });
}

/**
 * For newsletter submission we want to capture the event and push to the data
 * layer if the event was not prevented by dotdigital's inline javascript
 * alert based validation.
 */
if (newsletterSignupFormElement) {
  newsletterSignupFormElement.addEventListener('submit', function(event) {
    var defaultWasPrevented = event.defaultPrevented;
    if (!defaultWasPrevented) {
      var newsletterData = {
        category: 'form-submission',
        action: document.querySelector('#signup label[for*=addressbook]')
          .innerText,
        label: window.location.href,
      };
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(newsletterData);
    }
  });
}
