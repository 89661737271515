import Vue from 'vue';
import PaymentRoot from './components/PaymentRoot.vue';

const $appFormPlaceholders = document.querySelectorAll('.js-donate-form');

if ($appFormPlaceholders.length) {

  $appFormPlaceholders.forEach( ($appFormPlaceholder) => {
    const appType = $appFormPlaceholder.dataset.appType;
    const stripePublicKey = $appFormPlaceholder.dataset.stripePublicKey;
    const csrfToken = $appFormPlaceholder.dataset.csrfToken;
    const endpoints = {
      gocardless: {
        start: $appFormPlaceholder.dataset.gocardlessStart,
      },
      stripe: {
        intent: $appFormPlaceholder.dataset.stripeIntent,
        complete: $appFormPlaceholder.dataset.stripeComplete,
      }
    };

    new Vue({
      el: $appFormPlaceholder,
      name: 'PaymentApp',
      render: h => h(PaymentRoot, {
        props: {
          appType,
          stripePublicKey,
          csrfToken,
          endpoints
        }
      })
    });
  });


}
