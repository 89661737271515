import $ from 'jquery';
import _debounce from 'lodash/debounce';
import * as iframe from 'core/iframe';

$(window).on('load', () => {
  const $video = $('.js-home-hero .js-video');

  if ($video.length) {
    iframe.setDimensions($video)

    $(window).resize(_debounce( () => {
      iframe.setDimensions($video);
    }, 250));
    // after iframe has been calculated then fade in video
    $('.js-video-hidden').fadeIn()
  }
});
