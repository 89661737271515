export const listingConfig = {
  ThreeColTeaserStacked: {
    teaserComponent: 'TeaserStacked',
    teaserWrapperClasses: 'flex mb-20 last:mb-0 pb-20 last:pb-0 border-b last:border-0 border-color-base-keyline lg:w-1/3 lg:mb-0 lg:px-20 lg:pb-0 lg:border-r lg:last:border-r-0 lg:border-b-0',
    itemsPerRow: 3,
    resultsPerPage: 12,
  },
  DefaultNoImg: {
    showImage: false,
  },
  default: {
    teaserComponent: 'Teaser',
    rowClasses: 'pb-20 mb-20 border-b lg:px-20 border-color-base-keyline last:border-b-0 last:mb-0 last:pb-0',
    teaserWrapperClasses: 'flex mb-20 last:mb-0 pb-20 last:pb-0 border-b last:border-0 border-color-base-keyline lg:w-1/2 lg:mb-0 lg:px-20 lg:pb-0 lg:border-r lg:last:border-r-0 lg:border-b-0',
    itemsPerRow: 2,
    resultsPerPage: 16,
    showImage: true,
  }
}