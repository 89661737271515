<template>
  <div
    ref="donateFormTop"
    class="border border-color-base-keyline"
    :class="themeClass">
    <div class="border-b border-color-base-keyline">
      <PaymentNav :paymentSteps="paymentSteps" :step="step"/>
    </div>

    <div class="px-16 py-20 sm:px-30 md:px-60 md:py-40">

      <!-- Step 1 / Donation type and amount -->
      <!--
        keep-alive used here to ensure local state of child components is cached
        https://v2.vuejs.org/v2/api/#keep-alive
      -->
      <keep-alive>
        <component
          :is="step1Component"
          v-if="step === 0"
          :user-details="userDetails"
          :enabled-donation-options="enabledDonationOptions"
          :total-donation-amount="totalDonationAmount"
          :optional-donation-amount="optionalDonation"
          :selected-donation-type="donationType"
          :is-monthly-donation="isMonthlyDonation"
          :is-annual-donation="isAnnualDonation"
          :is-gift-aid-selected="isGiftAidSelected"
          :selected-donation-option-key="selectedDonationOptionKey"
          @set:totalDonationAmount="(value) => totalDonationAmount = value"
          @set:donationType="(value) => donationType = value"
          @set:recurringPaymentCount="(value) => recurringPaymentCount = value"
          @set:transactionAmount="(value) => transactionAmount = value"
          @set:isGiftAidSelected="(value) => isGiftAidSelected = value"
          @set:selectedDonationOptionKey="(value) => selectedDonationOptionKey = value"
          @set:paymentScheduleDetailText="(value) => paymentScheduleDetailText = value"
          @set:optionalDonation="(value) => optionalDonation = value"
          @formStepForward="formStepForward"
        >
        </component>
      </keep-alive>

      <!-- Step 2 / Your details -->
      <PaymentStep2
        v-if="step === 1"
        :user-details="userDetails"
        :is-one-off-donation="isOneOffDonation"
        :is-anonymous-donation="isAnonymousDonation"
        :include-will-information="includeWillInformation"
        :no-contact-donation="noContactDonation"
        :server-validation-issues="serverValidationIssues"
        @set:isAnonymousDonation="(value) => isAnonymousDonation = value"
        @set:includeWillInformation="(value) => includeWillInformation = value"
        @set:noContactDonation="(value) => noContactDonation = value"
        @formStepBack="formStepBack"
        @formStepForward="formStepForward"
      />

      <!-- Step 3 / Summary-->
      <PaymentStep3
        v-if="step === 2"
        :terms-accepted="termsAccepted"
        :transaction-amount="transactionAmount"
        :server-data="serverData"
        :total-donation-amount="totalDonationAmount"
        :optional-donation-amount="optionalDonation"
        :is-monthly-donation="isMonthlyDonation"
        :is-annual-donation="isAnnualDonation"
        :is-one-off-donation="isOneOffDonation"
        :payment-schedule-detail-text="paymentScheduleDetailText"
        @set:termsAccepted="(value) => termsAccepted = value"
        @set:stripeTransactionId="(value) => stripeTransactionId = value"
        @set:serverValidationIssues="(value) => serverValidationIssues = value"
        @formStepBack="formStepBack"
      />
    </div>
  </div>
</template>

<script>
import PaymentNav from './PaymentNav.vue';
import PaymentStep1Subscription from './PaymentStep1Subscription.vue';
import PaymentStep1Donation from './PaymentStep1Donation.vue';
import PaymentStep2 from './PaymentStep2.vue';
import PaymentStep3 from './PaymentStep3.vue';


export default {
  name: 'PaymentSteps',
  components: {
    PaymentNav,
    PaymentStep1Subscription,
    PaymentStep2,
    PaymentStep3
  },
  inject: ['appType'],
  props: {
    /**
     * enabledDonationOptions
     *
     * Which donation options can be displayed. These are rendered in the same
     * order as the array. Accepted values are `monthly` `yearly` `one-off` `pledge`
     */
    enabledDonationOptions: {
      type: Array,
      default() {
        return ['pledge', 'one-off', ]
      }
    },
  },
  data() {
    return {
      step: 0,
      recurringPaymentCount: null,
      transactionAmount: 0,
      donationType: '',
      totalDonationAmount: '',
      optionalDonation: 0,
      termsAccepted: false,
      stripeTransactionId: null,
      isGiftAidSelected: false,
      selectedDonationOptionKey: this.enabledDonationOptions[0],
      isAnonymousDonation: false,
      includeWillInformation: false,
      noContactDonation: false,
      paymentScheduleDetailText: '',
      userDetails: {
        title: '',
        first_name: '',
        last_name: '',
        postcode: '',
        address_1: '',
        address_2: '',
        town_city: '',
        county: '',
        country: '',
        email_address: '',
      },
      serverValidationIssues: {},
    };
  },
  computed: {
    paymentSteps() {
      if(this.appType == 'subscription') {
        return ['Payment', 'Your details', 'Summary'];
      } else {
        return ['Donation', 'Your details', 'Summary'];
      }
    },

    step1Component() {
      if(this.appType == 'subscription') {
        return PaymentStep1Subscription;
      } else {
        return PaymentStep1Donation
      }
    },

    themeClass() {
      if(this.appType == 'subscription') {
        return 'theme-ba-purple'
      } else {
        return 'theme-ba-stone'
      }
    },
    /**
     * transactionAmountInPence
     *
     * @returns {Number}
     */
    transactionAmountInPence() {
      return this.transactionAmount * 100;
    },

    /**
     * isMonthlyDonation
     *
     * @returns {Boolean}
     */
    isMonthlyDonation() {
      return this.donationType === 'monthly';
    },

    /**
     * isAnnualDonation
     *
     * @returns {Boolean}
     */
    isAnnualDonation() {
      return this.donationType === 'yearly';
    },

    /**
     * isOneOffDonation
     *
     * @returns {Boolean}
     */
    isOneOffDonation() {
      return this.donationType === 'one-off';
    },

    /**
     * serverData
     *
     * @returns {Object}
     */
    serverData() {
      return {
        type: this.donationType,
        monthly_donation: this.isMonthlyDonation,
        amount: this.transactionAmountInPence,
        anonymous: this.isAnonymousDonation,
        will_information: this.includeWillInformation,
        is_no_contact: this.noContactDonation,
        giftaid: this.isGiftAidSelected,
        terms: this.termsAccepted,

        // Spread user details object into server data
        ...this.userDetails,

        // Conditionally include GoCardless count if we have a pledge payment
        ...(this.recurringPaymentCount && {
          count: this.recurringPaymentCount,
        }),

        // Conditionally include Stripe transaction_id if it's a stripe payment
        ...(this.stripeTransactionId && {
          transaction_id: this.stripeTransactionId,
        }),
      }
    },


  },
  watch: {
    selectedDonationOptionKey(current) {
      if(current !== 'pledge') {
        this.paymentScheduleDetailText = '';
      }
    }
  },
  methods: {
    getContent(key) {
      console.log(this.content[this.appType][key]);
      return this.content[this.appType][key];
    },

    /**
     * scrollToFormTop
     */
    scrollToFormTop() {
      const scrollOptions = {
        top:
          this.$refs.donateFormTop.getBoundingClientRect().top +
          window.scrollY -
          40,
        left: 0,
      };

      if ('scrollBehavior' in document.documentElement.style) {
        scrollOptions.behaviour = 'smooth';
      }

      window.scrollTo(scrollOptions);
    },

    /**
     * Increment step and scroll user to top of form
     */
    formStepForward() {
      this.step++;
      this.scrollToFormTop();
    },

    /**
     * Decrement step and scroll user to top of form
     */
    formStepBack() {
      this.step--;
      this.scrollToFormTop();
    },
  },
};
</script>
