import $ from 'jquery';

$(window).on('load', () => {
  const $video = $('.js-primary-hero .js-video');
  const $playButton = $video.find('.js-button');
  const $thumbnail = $video.find('.js-thumbnail');

  $playButton.on('click', (e) => {
    const $button = $(e.currentTarget);
    $button.hide();
    $thumbnail.hide();
  });
});
