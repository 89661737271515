export default {
  props: {
    selectedDonationOptionKey: {
      type: String,
      required: true,
    },
    enabledDonationOptions: {
      type: Array,
      required: true
    },
    totalDonationAmount: {
      type: [Number, String],
      required: true
    },
    optionalDonationAmount: {
      type: Number,
      required: true
    },
    isMonthlyDonation: {
      type: Boolean,
      required: true,
    },
    isAnnualDonation: {
      type: Boolean,
      required: true,
    },
    isGiftAidSelected: {
      type: Boolean,
      required: true,
    },
    userDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDonationAmountError: null,
      paymentPeriodInYears: '',
      paymentSchedule: '',
      recurringTransactionCount: null,
      formStructure: {},
    }
  },
  computed: {
    donationComponent() {
      return this.selectedDonationOption.component
    },
    selectedDonationOption() {
      return this.donationOptions.find((donationOption) => donationOption.key === this.selectedDonationOptionKey);
    },
    enabledDonationOptionsData() {
      return this.donationOptions
        .filter(donationOption => this.enabledDonationOptions.includes(donationOption.key))
        .sort((a, b) => {
          const indexOfA = this.enabledDonationOptions.indexOf(a.key);
          const indexOfB = this.enabledDonationOptions.indexOf(b.key);

          if(indexOfA > indexOfB) return 1;
          if(indexOfA < indexOfB) return -1;
          if(indexOfA === indexOfB) return 0;
        });
    },
    donationOptionHasCount() {
      if(this.selectedDonationOption) {
        return this.selectedDonationOption.hasCount;
      }

      return null
    },
  },
  watch: {
    transactionAmount(value) {
      this.$emit('set:transactionAmount', value);
    }
  },
  methods: {
    indefiniteArticle(donationType) {
      if (donationType === 'annual') {
        return 'an';
      }

      return 'a';
    },
    /**
     * Convert number so it represents a currency value
     *
     * @param {Number} value to convert
     * @returns {Number} Converted number
     */
    convertNumberTo2DecimalPlaces(value) {
      return Math.round(value * 100) / 100;
    },

    /**
     * @returns {Number}
     */
    getTransactionAmount() {
      /**
       * If the user is paying 15,000 over 4 years on a monthly basis
       * the payment would be 15000 / (4 * 12)
       */
      if(!this.totalDonationAmount || !this.recurringTransactionCount) {
        return 0;
      }

      return this.convertNumberTo2DecimalPlaces(this.totalDonationAmount / this.recurringTransactionCount);

    },
    formStepForward() {
      this.$emit('formStepForward');
    },
    setRecurringPaymentCount(value) {
      this.recurringTransactionCount = value;

      if(this.donationOptionHasCount && this.recurringTransactionCount) {
        this.$emit('set:recurringPaymentCount', value);
      } else {
        this.$emit('set:recurringPaymentCount', null);
      }
    },
  }
}
