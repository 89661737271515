import $ from 'jquery';
import { ariaToggle } from 'numiko/a11y-toggle/a11y-toggle';

$('.js-transcript-dropdown').on('click', '.js-transcript-btn', e => {
  e.preventDefault();
  const $selectedButton = $(e.currentTarget);
  const $openLabel = $selectedButton.find('.js-open-label');
  const $closeLabel = $selectedButton.find('.js-close-label');
  const $transcriptContent = $selectedButton
    .closest('.js-transcript-dropdown')
    .find('.js-transcript-content');

  $openLabel.toggle();
  $closeLabel.toggle();

  $transcriptContent.stop().slideToggle(() => {
    ariaToggle($selectedButton, 'aria-expanded', $transcriptContent.is(':visible'));
    ariaToggle($transcriptContent, 'aria-hidden', $transcriptContent.is(':hidden'));
  });
});