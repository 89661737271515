// Images import automatically
require.context('../img/', true, /\.(png|svg|jpg|gif)$/);

/*
 * Stylesheet imports
 */
import '../scss/main.scss';

/*
 * Pollyfills
 * See js/modules/polyfills
 */
import 'modules/polyfills/intersection-observer';
import 'modules/polyfills/responsive-images';
import 'modules/polyfills/object-fit-images';

/*
 * Numiko common modules
 * See js/numiko
 */
import 'numiko/a11y-no-js/a11y-no-js';
import 'numiko/a11y-tabbing/a11y-tabbing';

/*
 * Site modules
 * See js/modules
 */
import 'modules/menu/desktop-menu';
import 'modules/menu/mobile-menu';
import 'modules/search/header-search';
import 'modules/search/table-search';
import 'modules/accordion/accordion';
import 'modules/donate/donate-form';
import 'modules/listing-page/filtered-list';

import 'modules/hero/home';
import 'modules/hero/primary';
import 'modules/back-to-top/back-to-top';
import 'modules/border/borders';
import 'modules/newsletter-form/newsletter-form';
import 'modules/newsletter-form/newsletter-footer';
import 'modules/jumplinks/jumplinks';
import 'modules/lightbox/lightbox';
import 'modules/podcast/podcast-toggle';
import 'modules/transcript/transcript-toggle';
import 'modules/countdown/countdown';
import 'modules/gallery/masonry';
import 'modules/tableau-embed/tableau-embed';
