<template>

  <div class>
    <h2 class="max-w-2xl font-heading-sans-serif text-40 lg:text-56 leading-heading text-color-themed-primary mb-30">
      Terms and conditions
    </h2>

    <div v-if="showDirectDebitGuarantee">
      <h3 class="font-body-serif text-24 leading-snug mb-30">
        The Direct Debit Guarantee
      </h3>

      <p class="font-body-sans-serif text-16 text-color-grey-mid mb-20">
        This guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits.
        If there are any changes to the amount, date or frequency of your Direct Debit the British Academy will notify
        you 10 working days in advance of your account being debited or as otherwise agreed. If you request The British
        Academy to collect payment, confirmation of the amount and date will be given to you at the time of request.
        If an error is made in the payment of your Direct Debit by The British Academy or your bank of building society,
        you are entitled to a full and immediate refund of the amount paid from your bank or building society.
        If you receive a refund you are not entitled to, you must pay it back when The British Academy asks you to.
        You can cancel a Direct Debit at any time by simply contacting your bank or building society.
        Written confirmation may also be required. Please also notify us.
      </p>
    </div>

    <div class="max-w-xl mb-20">
      <InputCheckbox
        v-model="termsAcceptedModel"
        label="I confirm that the details are correct"
        id="id_terms"
        name="terms"
        :inputAttributes="{
          required: true,
        }"
      />
    </div>

    <p
      class="hidden font-body-sans-serif text-16 text-color-grey-mid"
      data-optional="monthly"
    >
      Please note that clicking &lsquo;confirm payment&rsquo; will take you to our 3rd party provider.
    </p>
  </div>

</template>

<script>

import InputCheckbox from './InputCheckbox.vue';

export default {
  components: {
    InputCheckbox,
  },
  props: {
    showDirectDebitGuarantee: {
      type: Boolean,
      required: true
    },
    termsAccepted: {
      type: Boolean,
      required: true
    },
  },
  computed: {
    termsAcceptedModel: {
      get() {
        return this.termsAccepted;
      },
      set(value) {
        this.$emit('set:termsAccepted', value);
      },
    }
  },
}

</script>
