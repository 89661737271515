<template>
  <ul class="list-none flex p-0 m-0">
    <li
      class="border-r border-color-base-keyline last:border-r-0 flex-1 md:flex-none md:last:border-r"
      v-for="(item, index) in paymentSteps"
      :key="item"
    >
      <span
        :class="{'border-color-themed-primary': step === index, 'text-color-grey-mid': step !== index }"
        class="flex items-center justify-center h-full font-founders-grotesk font-semibold no-underline border-t-4 border-color-transparent py-3 px-16 text-center md:py-3 md:px-60"
      >
        {{ item }}
      </span>
    </li>
  </ul>
</template>


<script>
export default {
  name: 'PaymentNav',
  props: {
    paymentSteps: {
      type: Array,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    }
  },
};
</script>
