<template>
  <li class="md:border-r md:border-color-base-keyline">
    <button
      class="flex justify-between items-center w-full md:w-auto md:h-full cursor-pointer text-left md:text-center font-founders-grotesk font-semibold text-18 bg-color-transparent md:border-t-4 md:border-color-transparent px-0 md:px-40 py-10 md:py-20 m-0 tabbing-focus:shadow-focus"
      :class="{'md:border-color-ba-stone': isSelectedFilter || hasActiveFilters }"
      @mousedown.prevent="selectFilterGroup(group.slug)"
      :aria-label="'filter for ' + group.label"
      :aria-controls="group.label"
      :aria-expanded="group.slug == selectedFilterGroupId ? 'true' : 'false'"
      @focus="buttonFocus(group.slug)"
    >
      {{ group.label }}

      <svg class="md:hidden w-20 h-20 text-color-themed-primary icon"
        focusable="false"
        :aria-hidden="!isSelectedFilter">
        <use :xlink:href="isSelectedFilter ? '#sprite-icon-minus-in-circle' : '#sprite-icon-plus-in-circle'"/>
      </svg>
    </button>

    <transition-group :name="filterGroupTransitionName">
      <ListFiltersDropdown
        v-show="isSelectedFilter"
        :group="group"
        :selected-filters="activeFilters[group.slug]"
        :key="group.slug"
        @close-filter-group="closeFilterGroup"
        @select-filter="selectFilter"
      />
    </transition-group>
  </li>

</template>

<script>

import config from '../../../config/config';
import ListFiltersDropdown from './ListFiltersDropdown.vue';

export default {
  name: 'ListFiltersCategory',
  components: {
    ListFiltersDropdown
  },
  props: {
    group: {
      type: Object,
      required: true
    },
    activeFilters: {
      type: Object,
      required: true
    },
    selectedFilterGroupId: {
      type: String,
      required: true
    },
    filterGroupTransitionName: {
      type: String,
      required: true
    }
  },
  computed: {
    hasActiveFilters() {
      return this.group.slug in this.activeFilters;
    },
    isSelectedFilter() {
      return this.group.slug === this.selectedFilterGroupId;
    }
  },
  methods: {

    /**
     * buttonFocus
     *
     * Opens filter group when focussed using tabbing
     *
     * @param {String} id
     *
     */
    buttonFocus(id) {
      this.selectFilterGroup(id);
    },

    /**
     * closeFilterGroup
     *
     * Inform parent component to close filter group
     */
    closeFilterGroup() {
      this.$emit('close-filter-group');
    },

    /**
     * selectFilterGroup
     *
     * Inform parent component to select filter group
     *
     * @param {String} filterGroup
     */
    selectFilterGroup(filterGroup) {
      this.$emit('select-filter-group', filterGroup);
    },

    /**
     * selectFilter
     *
     * Inform parent component to select filter
     *
     * @param {String} category
     * @param {String} filter
     */
    selectFilter(category, filter) {
      this.$emit('select-filter', category, filter);
    },
  }
}
</script>
