// Javascript to remove the top border of the first slice on the page
import $ from "jquery";

$(".js-block-item:first")
    .removeClass('my-50 md:my-70 lg:my-80')
    .addClass('mb-50 mt-40 md:mb-70 lg:mb-80 lg:mt-60 first-block-item')
	.children()
	.children()
    .removeClass("md:border-t")
    

let blockList = $(".js-block-item");

blockList.each(function() {
	if (!$(this).prev().hasClass("js-block-item") && !$(this).hasClass("first-block-item")) {
        $(this)
            .removeClass('my-50 md:my-70 lg:my-80')
            .addClass('mt-40 mb-50 md:mb-70 md:mt-50 lg:mt-60 lg:mb-80')

		$(this)
			.children()
			.children()
			.removeClass("md:border-t");
	}
});
