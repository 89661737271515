<template>
    <div class="h-full">
        <button
            :aria-expanded="searchActive"
            aria-controls="header-search-form"
            @click="toggleSearchPanel"
            class="js-toggle-search-btn | h-full flex justify-center items-center text-color-black bg-color-transparent sm:border-t-5 border-color-transparent p-10 sm:px-30 md:px-40 lg:px-20 xl:px-30 m-0 cursor-pointer md:hover:text-color-white md:hover:bg-color-black tabbing-focus:shadow-focus-inset">
            <span class="visually-hidden">Search</span>

            <span class="flex justify-center items-center w-20 h-20">
                <svg
                    v-if="!searchActive"
                    class="block w-20 h-20"
                    focusable="false"
                    role="presentation">
                    <use xlink:href="#sprite-icon-search"></use>
                </svg>

                <svg v-if="searchActive"
                     class="block w-4 h-4"
                     focusable="false"
                     role="presentation">
                    <use xlink:href="#sprite-icon-cross"></use>
                </svg>
            </span>
        </button>

        <transition name="scale-fade">
            <form
                v-if="searchActive"
                id="header-search-form"
                class="js-search-panel | fixed inset-x-0 bottom-0 top-75 sm:top-101 md:top-97 text-color-white bg-color-ba-dark-grey"
                :action="$root.actionUrl"
                :aria-hidden="!searchActive">
                <div class="container | overflow-auto no-scrollbars-on-scroll h-full mx-auto mt-auto py-50 sm:py-60 px-20 md:px-40 lg:px-50">
                    <fieldset class="animation-delay-slide-up-fade | relative p-0 m-0">
                        <label for="searchbar" class="visually-hidden">Search</label>
                        <input
                            class="w-full appearance-none font-heading-sans-serif leading-heading text-30 md:text-40 lg:text-80 text-color-white bg-color-transparent border-l-0 border-r-0 border-t-0 border-b border-color-base-keyline-dark rounded-none focus:outline-none focus:border-color-white py-3 pr-40 tabbing-focus:shadow-focus"
                            type="search"
                            name="query"
                            placeholder="Search"
                            aria-label="Search"
                            autocomplete="off"
                            id="searchbar"
                            v-model="searchValue"
                            @keyup="getSearchResults"
                            ref="searchInput"/>

                        <button
                            type="submit"
                            class="absolute right-0 bottom-0 flex justify-center items-center border-none bg-color-transparent text-color-white p-10 mb-2 lg:mb-4 tabbing-focus:shadow-focus">
                            <span class="visually-hidden">Search</span>
                            <svg class="block w-20 h-20" focusable="false" role="presentation">
                                <use xlink:href="#sprite-icon-search"></use>
                            </svg>
                        </button>
                    </fieldset>

                    <transition-group
                        tag="ul"
                        class="list-none p-0 m-0"
                        name="staggered-fade"
                        :css="false"
                        v-on:before-enter="beforeEnter"
                        v-on:enter="enter"
                        v-on:leave="leave">
                        <li
                            v-for="(result, index) in searchResults"
                            :key="result.title"
                            :data-index="index"
                            class="first:border-t border-b border-color-base-keyline-dark py-30 first:mt-40 md:first:mt-100 transition-opacity">
                            <a :href="result.url" class="flex flex-wrap md:w-2/3 no-underline group font-heading-serif text-22 text-color-base-keyline tabbing-focus:shadow-focus">
                                <h3 class="order-3 inline group-hover:underline font-body-serif text-22 tracking-tighter leading-normal m-0">{{ result.title }}</h3>
                                <span v-if="result.teaser_meta.author" class="order-2 text-color-ba-stone">{{ result.teaser_meta.author }} /&nbsp;</span>
                                <span v-if="result.teaser_meta.publish_date" class="order-1 w-full font-body-sans-serif text-12 uppercase mb-20">{{ result.teaser_meta.publish_date.formatted }}</span>
                                <span v-if="result.teaser_meta.event_date" class="order-1 w-full font-body-sans-serif text-12 uppercase mb-20">{{ result.teaser_meta.event_date.formatted }} <span v-if="result.teaser_meta.location"> - {{ result.teaser_meta.location }}</span></span>
                            </a>
                        </li>
                    </transition-group>
                </div>
            </form>
        </transition>
    </div>
</template>

<script>
  import $ from 'jquery';
  import _debounce from 'lodash/debounce';

  export default {
    data() {
      return {
        searchActive: false,
        searchValue: '',
        searchResults: [],
        animationDelay: 100,
      };
    },
    methods: {
      getSearchResults: _debounce(function() {
        $.get(`/search/autocomplete/?query=${this.searchValue}`, data => {
          if (Array.isArray(data.results)) {
            this.searchResults = data.results;
          } else {
            this.searchResults = [];
          }
        });
      }, 200),
      toggleSearchPanel() {
        this.searchActive = !this.searchActive;

        // toggle no scroll class on <body> tag
        document.getElementsByTagName('body')[0].classList.toggle('no-scroll');

        if (this.searchActive) {
          // close "desktop more menu" if open
          if ($('.js-more-menu:visible').length) {
            $('.js-more-menu-btn').trigger('click');
          }
          // close "mobile nav" if open
          if (
            $('.js-menu-btn:visible').length &&
            $('.js-mobile-navigation:visible').length
          ) {
            $('.js-menu-btn').trigger('click');
          }

          this.scrollWindowToTop();

          // wait for the dom to update then focus input field
          this.$nextTick(() => {
            this.$refs.searchInput.focus();
          });
        }
      },
      scrollWindowToTop() {
        window.scrollTo(0, 0);
      },
      beforeEnter: function (el) {
        el.style.opacity = 0;
      },
      enter: function (el, done) {
        var delay = el.dataset.index * this.animationDelay;

        setTimeout(function () {
          el.style.opacity = 1;
          done();
        }, delay);
      },
      leave: function (el, done) {
        var delay = el.dataset.index * this.animationDelay;

        el.style.opacity = 0;
        setTimeout(function () {
          done();
        }, delay);
      }
    },
    beforeMount() {
      if (this.$root.initialSearchQuery) {
        this.searchValue = this.$root.initialSearchQuery;
        this.getSearchResults();
      }
    },
  };
</script>