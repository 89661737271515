<template>
  <a
    :href="teaser.meta.html_url"
    class="animation-icon-slide-replace | flex flex-col h-full w-full no-underline tabbing-focus:shadow-focus">
    <!-- Image -->
    <div v-if="teaser.teaser_image_landscape_small" class="flex-none mb-20 aspect-ratio-15/9 relative">
      <img
        :srcset="srcSet"
        sizes="(max-width: 1024px) 100vw, 33vw"
        :src="teaser.teaser_image_landscape_small.url"
        width="400"
        height="240"
        alt=""
        class="block w-full h-full absolute top-0 left-0 object-cover"
      />
    </div>

    <!-- Meta data -->
    <p v-if="formattedMeta" class="mb-10 uppercase font-body-sans-serif text-12">
      <span v-if="formattedMeta" v-html="formattedMeta" class="pipe-after-element"></span>
    </p>

    <!-- Title -->
    <h3
      v-if="teaser.title"
      class="mb-16 font-heading-serif text-22 md:text-24"
    >
      {{ teaser.title }}
    </h3>

    <!-- Meta data -->
    <p class="mb-20 font-body-sans-serif text-15 text-color-grey-mid lg:mb-30">
      <span v-if="eventDate">{{ eventDate }}<template v-if="teaser.city">,</template></span>
      <span v-if="teaser.city"> {{ teaser.city }}</span>
    </p>

    <!-- Introduction -->
    <p
      v-if="teaser.teaser_summary"
      class="mb-20 font-body-sans-serif text-15 text-color-grey-mid lg:mb-30"
    >
      {{ teaser.teaser_summary }}
    </p>

    <!-- Icon -->
    <span
      class="flex items-center w-20 h-10 overflow-hidden"
      aria-hidden="true"
    >
      <svg
        class="flex-shrink-0 w-full h-full text-color-themed-primary icon"
        role="presentation"
        focusable="false"
      >
        <use xlink:href="#sprite-icon-right-arrow"></use>
      </svg>
      <svg
        class="flex-shrink-0 w-full h-full text-color-themed-primary icon"
        role="presentation"
        focusable="false"
      >
        <use xlink:href="#sprite-icon-right-arrow"></use>
      </svg>
    </span>
  </a>
</template>

<script>
import teaserMixin from '../../mixins/teaserMixin';

export default {
  name: 'TeaserStacked',
  props: ['teaser'],
  mixins: [teaserMixin],
  computed: {
    srcSet() {
      return `${this.teaser.teaser_image_landscape_small.url} 400w, ${this.teaser.teaser_image_landscape_large.url} 1200w`;
    },
    formattedMeta() {
      let types = [];

      if(this.teaser.types) {
        types = this.teaser.types.map(type => `<span>${type.name}</span>`);
      }

      if(this.teaser.free) {
        types.push(`<span class="uppercase">Free</span>`)
      }

      return types.join(' ');
    }
  },
};
</script>