var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outline-none",attrs:{"id":"filtered-list"}},[(_vm.hasFilters && _vm.hideFilter === false)?_c('ListFilters',{attrs:{"filters":_vm.visibleFilters,"descendant-of":_vm.descendantOf,"ordering":_vm.ordering,"order-by":_vm.orderBy,"active-filters":_vm.activeFilters},on:{"change-order-by":_vm.changeOrderBy,"select-filter":_vm.selectFilter}}):_vm._e(),_vm._v(" "),_c('ListResults',{attrs:{"results":_vm.results,"has-loaded-data":_vm.hasLoadedData,"loading":_vm.loading,"listingDisplay":_vm.listingDisplay},on:{"clear-filters":_vm.clearFilters}}),_vm._v(" "),(_vm.pageCount > 1)?_c('Pagination',{attrs:{"page-count":_vm.pageCount,"clickHandler":_vm.changePage,"prev-text":`
          <span class="visually-hidden">Previous</span>
          <span class="flex items-center w-20 h-10 overflow-hidden">
              <svg class="flex-shrink-0 w-full h-full text-color-themed-primary icon">
                  <use xlink:href="#sprite-icon-right-arrow"></use>
              </svg>
              <svg class="flex-shrink-0 w-full h-full text-color-themed-primary icon">
                  <use xlink:href="#sprite-icon-right-arrow"></use>
              </svg>
          </span>`,"next-text":`
          <span class="visually-hidden">Next</span>
          <span class="flex items-center w-20 h-10 overflow-hidden">
              <svg class="flex-shrink-0 w-full h-full text-color-themed-primary icon">
                  <use xlink:href="#sprite-icon-right-arrow"></use>
              </svg>
              <svg class="flex-shrink-0 w-full h-full text-color-themed-primary icon">
                  <use xlink:href="#sprite-icon-right-arrow"></use>
              </svg>
          </span>`,"prev-link-class":'animation-icon-slide-replace | flex items-center h-full font-founders-grotesk font-semibold px-4 py-2 rotate-180 tabbing-focus:shadow-focus',"next-link-class":'animation-icon-slide-replace | flex items-center h-full font-founders-grotesk font-semibold px-4 py-2 tabbing-focus:shadow-focus',"active-class":'group bg-color-black text-color-white',"hide-prev-next":true,"container-class":'flex justify-center list-none px-40 py-0 mx-0 mt-0 mb-60',"page-class":'my-0 mx-10',"page-link-class":'block font-founders-grotesk font-semibold px-4 py-2 border border-color-base-keyline group-hover:bg-color-white group-hover:text-color-black tabbing-focus:shadow-focus'},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }