<template>
  <div class="relative pt-20 mb-6">
    <slot name="label" :id="id" :label="label">
      <label
        :class="{ 'opacity-0': !value }"
        class="form-label--small | absolute top-0 left-0 transition transition-opacity"
        :for="id"
      >
        {{ label }}

        <template v-if="inputAttributes.required">
          <sup aria-hidden="true">*</sup>
        </template>
      </label>
    </slot>

    <select
      v-model="model"
      :id="id"
      class="form-input tabbing-focus:shadow-focus"
      v-bind="inputAttributes"
    >
      <option disabled value>{{ defaultOption }}</option>
      <option
        v-for="option in options"
        :key=option.key
        :value=option.key
      >
        {{ option.label }}
      </option>
    </select>

    <InputValidationMessage
      v-if="validationErrors"
      :validation-errors="validationErrors"
    />

  </div>
</template>

<script>

import InputValidationMessage from './InputValidationMessage.vue'

export default {
  components: {
    InputValidationMessage,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    inputAttributes: {
      type: Object,
      default() {
        return {}
      },
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    defaultOption: {
      type: String,
      default: 'Select',
    },
    options: {
      type: Array,
      required: true,
    },
    validationErrors: {
      type: Array,
      default() {
        return []
      },
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
}
</script>
