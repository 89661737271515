<template>
  <div id="filtered-list" class="outline-none">
    <ListFilters
      v-if="hasFilters && hideFilter === false"
      :filters="visibleFilters"
      :descendant-of="descendantOf"
      :ordering="ordering"
      :order-by="orderBy"
      :active-filters="activeFilters"
      @change-order-by="changeOrderBy"
      @select-filter="selectFilter"
    />

    <ListResults
      :results="results"
      :has-loaded-data="hasLoadedData"
      :loading="loading"
      :listingDisplay="listingDisplay"
      @clear-filters="clearFilters"
    />

    <Pagination
      v-if="pageCount > 1"
      v-model="page"
      :page-count="pageCount"
      :clickHandler="changePage"
      :prev-text='`
            <span class="visually-hidden">Previous</span>
            <span class="flex items-center w-20 h-10 overflow-hidden">
                <svg class="flex-shrink-0 w-full h-full text-color-themed-primary icon">
                    <use xlink:href="#sprite-icon-right-arrow"></use>
                </svg>
                <svg class="flex-shrink-0 w-full h-full text-color-themed-primary icon">
                    <use xlink:href="#sprite-icon-right-arrow"></use>
                </svg>
            </span>`'
      :next-text='`
            <span class="visually-hidden">Next</span>
            <span class="flex items-center w-20 h-10 overflow-hidden">
                <svg class="flex-shrink-0 w-full h-full text-color-themed-primary icon">
                    <use xlink:href="#sprite-icon-right-arrow"></use>
                </svg>
                <svg class="flex-shrink-0 w-full h-full text-color-themed-primary icon">
                    <use xlink:href="#sprite-icon-right-arrow"></use>
                </svg>
            </span>`'
      :prev-link-class="'animation-icon-slide-replace | flex items-center h-full font-founders-grotesk font-semibold px-4 py-2 rotate-180 tabbing-focus:shadow-focus'"
      :next-link-class="'animation-icon-slide-replace | flex items-center h-full font-founders-grotesk font-semibold px-4 py-2 tabbing-focus:shadow-focus'"
      :active-class="'group bg-color-black text-color-white'"
      :hide-prev-next="true"
      :container-class="'flex justify-center list-none px-40 py-0 mx-0 mt-0 mb-60'"
      :page-class="'my-0 mx-10'"
      :page-link-class="'block font-founders-grotesk font-semibold px-4 py-2 border border-color-base-keyline group-hover:bg-color-white group-hover:text-color-black tabbing-focus:shadow-focus'"
    />
  </div>
</template>

<script>
import { listingConfig } from '../config';
import $ from 'jquery';
import { smoothScroll } from '../../../numiko/smooth-scroll/smooth-scroll';
import _chunk from 'lodash/chunk';
import _assign from 'lodash/assign';
import ListFilters from './ListFilters.vue';
import ListResults from './ListResults.vue';
import { default as Pagination } from 'vuejs-paginate';

export default {
  name: 'FilteredList',
  metaInfo() {
    /**
     * We have to set this here to ensure the canonical
     * URL is reactive when used with Vue Meta.
     *
     * https://vue-meta.nuxtjs.org/guide/caveats.html
     */
    const canonical = this.canonicalURL;

    return {
      link: [{ rel: 'canonical', href: canonical }],
    };
  },
  components: {
    ListFilters,
    ListResults,
    Pagination,
  },
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
    // Optionally restrict results to descendants of the current filter page.
    // currently only BookPageFilterAPIViewSet uses descendantOf
    descendantOf: {
      type: Number,
    },
    // The user can choose whether to hide the filter, and only show results
    hideFilter: {
      type: Boolean,
    },
    listingDisplay: {
      type: String,
      required: true,
    },
    defaultOrderBy: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hasLoadedData: false,
      loading: false,
      results: [],
      filters: [],
      apiSelectedFilters: [],
      ordering: {},
      totalResultsCount: 0,
      activeFilters: {},
      page: 1,
      cachedUrl: '',
      baseUrl: '',
      orderBy: '',
      canonicalURL: '',
    };
  },
  computed: {
    resultsPerPage() {
      if (listingConfig[this.listingDisplay].hasOwnProperty('resultsPerPage')) {
        return listingConfig[this.listingDisplay].resultsPerPage;
      }

      return listingConfig['default'].resultsPerPage;
    },
    hasFilters() {
      return Object.keys(this.filters).length > 0;
    },
    visibleFilters() {
      return this.filters.filter(filter => filter.display === true);
    },
    pageCount() {
      return Math.ceil(this.totalResultsCount / this.resultsPerPage);
    },
  },
  created() {
    /**
     * When app first initialises get the results from the current page url
     */
    this.getResultsFromUrlString();

    /**
     * Vue Meta adds a dynamic canonical tag, so we can safely remove
     * the hard-coded version when we initiate the application.
     */
    this.removeHardcodedCanonical();

    window.addEventListener('popstate', this.popState);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.popState);
  },
  methods: {
    /**
     * popState
     *
     * Update results when user navigates using browser controls
     *
     * @param {Object} event
     */
    popState(event) {
      this.getResultsFromUrlString(false);
    },

    /**
     * changePage
     *
     * Sets the selected page and gets relevant results when pagination button has been used
     *
     * @param {Number} selectedPage
     */
    changePage(selectedPage) {
      smoothScroll('#filtered-list', 0, 500);
      this.page = selectedPage;
      this.getResults();
    },

    /**
     * changeOrderBy
     *
     * Sets the sorting method and gets relevant results when sorting button has been used
     *
     * @param {String} orderBy
     */
    changeOrderBy(orderBy) {
      // Reset to page 1 when changing sorting
      this.page = 1;
      this.orderBy = orderBy;
      this.getResults();
    },

    /**
     * selectFilter
     *
     * Adds/removes selected filter to results
     *
     * @param {Sring} category
     * @param {String} filter
     */
    selectFilter(category, filter) {
      const filters = this.buildOrderedFilters(category, filter);

      // Reset to page 1 when adding new filter
      this.page = 1;
      this.getResults(filters);
    },

    /**
     * removeHardcodedCanonical
     *
     * Vue Meta creates its own canonical tag, so upon creation
     * of the Vue application, we can remove the hard-coded version
     * supplied by Wagtail.
     */
    removeHardcodedCanonical() {
      const canonical = document.querySelector('link[rel="canonical"]');

      if (canonical) {
        canonical.remove();
      }
    },

    /**
     * clearFilters
     *
     * Sets filters back to initialState
     */
    clearFilters() {
      this.getResults('');
    },

    /**
     * getResultsFromUrlString
     *
     * Gets results from url string to set initial data when first loading the page or
     * using browser navigation controls
     *
     * @param {Boolean} pushState
     */
    getResultsFromUrlString(pushState = true) {
      this.baseUrl = `/${window.location.pathname.split('/')[1]}/`;
      const query = this.getQueryStringAsObject();
      const filters = window.location.pathname.split(this.baseUrl)[1];

      if (query.page) {
        this.page = Number(query.page);
      }

      if (query.order) {
        this.orderBy = query.order;
      }

      this.getResults(filters, pushState);
    },

    /**
     * getResults
     *
     * Makes API request for data from the server and sets properties when complete
     *
     * @param {String} filters
     * @param {Boolean} pushState
     */
    getResults(filters = this.cachedUrl, pushState = true) {
      const fullPageUrl = `${this.baseUrl}${filters}`;
      let endpointUrl = `${this.apiUrl}${filters}?fields=*&page=${this.page}`;

      this.loading = true;
      this.cachedUrl = filters;

      if (this.descendantOf) {
        endpointUrl += `&descendant_of=${this.descendantOf}`;
      }

      if(listingConfig[this.listingDisplay].hasOwnProperty('resultsPerPage')) {
        endpointUrl += `&results=${listingConfig[this.listingDisplay].resultsPerPage}`;
      }

      // Only show order if there are also filters being displayed
      // If an order hasn't been set on the query string but a default has
      // been set in the CMS, use the CMS order
      if (this.orderBy) {
        endpointUrl += `&order=${this.orderBy}`;
      } else if (this.defaultOrderBy) {
        // Default order can be managed in the CMS on book pages
        endpointUrl += `&order=${this.defaultOrderBy}`;
      }

      return $.get(endpointUrl)
        .done((data) => {
          this.results = data.results;
          this.filters = data.filters;
          this.apiSelectedFilters = data.selected_filters;
          this.ordering = data.ordering;
          this.totalResultsCount = data.count;
          this.orderBy = data.selected_order[0];

          this.setActiveFilters();

          let frontendUrl = fullPageUrl;

          if (this.page > 1) {
            frontendUrl = `${frontendUrl}?page=${this.page}`;
          }

          // Only show order if there are also filters being displayed
          if (this.hasFilters && this.orderBy) {
            const frontEndUrlQuerySeparator = this.page > 1 ? '&' : '?';
            frontendUrl = `${frontendUrl}${frontEndUrlQuerySeparator}order=${this.orderBy}`;
          }

          // push new url into browser history
          if (pushState) {
            window.history.pushState(
              { page: this.page, sortBy: this.orderBy },
              '',
              frontendUrl
            );
          }

          // Update the canonical URL
          this.setCanonicalURL(frontendUrl);
        })
        .fail(function (response, ajaxOptions, thrownError) {
          try {
            console.error(response.responseJSON.message);
          } catch (e) {
            console.error(
              `Error from filters API - ${response.status} (${thrownError})`
            );
          }
        })
        .always(() => {
          this.loading = false;
          this.hasLoadedData = true;
        });
    },

    /**
     * getQueryStringAsObject
     *
     * Converts query parameters to object. Used instead of URLSearchParams to support IE
     *
     * @returns {Object}
     */
    getQueryStringAsObject() {
      const pairs = window.location.search.slice(1).split('&');
      const result = {};

      pairs.forEach((pair) => {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
      });

      return result;
    },

    /**
     * sortArrayAlphabetically
     *
     * Sorts contents of array alphabetically
     *
     * @param {Array} input
     */
    sortArrayAlphabetically(input) {
      return input.sort((a, b) => (a < b ? -1 : 1));
    },

    /**
     * setCanonicalURL
     *
     * @param {String} frontendUrl - Full URL excluding protocol and hostname.
     *
     * This will update the canonical URL upon results
     * successfully being fetched from the back-end.
     */
    setCanonicalURL(frontendUrl) {
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const frontendUrlWithoutQueryParams = frontendUrl.split('?')[0];

      this.canonicalURL = `${protocol}//${hostname}${frontendUrlWithoutQueryParams}`;
    },

    /**
     * setActiveFilters
     *
     * This sits in between the state of the app and the data returned from the API to ensure the app
     * state always stays in sync with the data. This is because when a page initially loads with
     * filters the app needs to know what filters are already set so it can add / remove correctly.
     *
     */
    setActiveFilters() {
      const activeFiltersFormatted = {};
      const itemsWithSelectedFilters = this.apiSelectedFilters.filter(
        (selected) => selected.items.length
      );

      itemsWithSelectedFilters.forEach((category) => {
        activeFiltersFormatted[category.slug] = category.items.map(
          (item) => item.slug
        );
      });

      this.activeFilters = activeFiltersFormatted;
    },

    /**
     * buildOrderedFacets
     *
     * Order filters alphabetically into each group. First each group is ordered alphabetically,
     * then filters within the group
     *
     * @param {String} category
     * @param {String} filter
     *
     * @returns {String}
     */
    buildOrderedFilters(category, filter) {
      const orderedFilters = [];

      if (category in this.activeFilters) {
        const index = this.activeFilters[category].indexOf(filter);

        // Add new item to category if it already exists
        if (index >= 0) {
          // If item is already selected, remove it from the selection
          this.activeFilters[category].splice(index, 1);
        } else {
          // Add the item to the selection if it's not already selected
          this.activeFilters[category].push(filter);
        }

        // Sort filters alphabetically
        this.activeFilters[category] = this.sortArrayAlphabetically(
          this.activeFilters[category]
        );
      } else {
        // Used instead of Object.assign for IE support
        _assign(this.activeFilters, { [category]: [filter] });
      }

      if (this.activeFilters[category].length === 0) {
        delete this.activeFilters[category];
      }

      this.sortArrayAlphabetically(Object.keys(this.activeFilters)).forEach(
        (category) => {
          orderedFilters.push(
            `${category}/${this.activeFilters[category].join('/')}`
          );
        }
      );

      return orderedFilters.length ? `${orderedFilters.join('/')}/` : '';
    },
  },
};
</script>