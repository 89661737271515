import $ from 'jquery';

const options = {
  item: '.js-table-filter',
  input: '.js-table-filter__input',
  table: '.js-table-filter__table',
  header: '.js-table-filter__header',
  row: '.js-table-filter__row',
  fallback: '.js-table-filter__fallback',
  term: '.js-table-filter__term',
};

// Filter based on search value
$('body').on('keyup', options.input, function() {
  filterTable(false, $(this));
});

// Reset filter if search cleared
$('body').on('search', options.input, function() {
  filterTable(true, $(this));
});

function filterTable(clear, element) {
  const item = $(element).closest(options.item);
  const value = $(element)
    .val()
    .toLowerCase();

  $(item)
    .find(options.row)
    .filter(function() {
      if (!clear) {
        $(this).toggle(
          $(this)
            .text()
            .toLowerCase()
            .indexOf(value) > -1
        );
      } else {
        $(this).show();
        $(item)
          .find(options.header)
          .show();
        $(item)
          .find(options.fallback)
          .hide();
      }
    });

  // Display no results block
  if ($(item).find(options.row + ':visible').length === 0) {
    $(item)
      .find(options.header)
      .hide();
    $(item)
      .find(options.term)
      .text(value);
    $(item)
      .find(options.fallback)
      .show();
  } else {
    $(item)
      .find(options.header)
      .show();
    $(item)
      .find(options.fallback)
      .hide();
  }
}
