import InputSelect from '../components/InputSelect.vue';
import PaymentAmountButtons from '../components/PaymentAmountButtons.vue';

export default {
  components: {
    InputSelect,
    PaymentAmountButtons,
  },
  props: {
    donationOption: {
      type: Object,
      required: true,
    },
    totalDonationAmount: {
      type: [Number, String],
      required: true
    },
  },
  data() {
    return {

    }
  },
  computed: {
    donationAmountModel: {
      get() {
        return this.totalDonationAmount;
      },
      set(value) {
        this.setDonationTotal(value);
      }
    },
  },
  methods: {
    formStep1Submit(event) {
      if (event.target.checkValidity()) {
        this.formStepForward();
      }
    },
    setDonationType(value) {
      this.$emit('set:donationType', value);
    },
    setDonationTotal(value) {
      this.$emit('set:totalDonationAmount', value);
    },
    formStepForward() {
      this.$emit('formStepForward');
    },
  }
}
