<template>
    <section class="relative z-0 my-50 md:my-70 lg:my-80">
        <LoadingIcon :loading="loading" />

        <div v-if="hasLoadedData" class="container relative px-20 mx-auto md:px-40 lg:px-50">
            <div
              v-for="(chunk, index) in resultChunks"
              :class="rowClasses"
              :key="index"
            >
                <div class="lg:flex lg:-mx-40">
                    <div
                        v-for="(item, index) in chunk"
                        :key="index"
                        :class="teaserWrapperClasses">

                        <component
                          :is="teaserComponent"
                          :teaser="item"
                          :showImage="shouldShowImage"
                        />
                    </div>
                </div>
            </div>

            <p
                v-if="!results.length"
                class="text-18 ml-20">
                No results <button type="button" @click.prevent="$emit('clear-filters')" class="p-0 underline font-body-serif text-18 cursor-pointer bg-color-transparent hover:no-underline tabbing-focus:shadow-focus">clear filters</button>
            </p>
        </div>
    </section>
</template>

<script>
  import { listingConfig } from '../config';
  import Teaser from './teasers/Teaser.vue';
  import TeaserStacked from './teasers/TeaserStacked.vue';
  import LoadingIcon from './LoadingIcon.vue';
  import _chunk from "lodash/chunk";

  export default {
    name: 'ListResults',
    components: {
      Teaser,
      TeaserStacked,
      LoadingIcon
    },
    props: {
      results: {
        type: Array,
        required: true
      },
      listingDisplay: {
        type: String,
        required: true
      },
      hasLoadedData: {
        type: Boolean,
        required: true
      },
      loading: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      teaserComponent() {
        if(listingConfig[this.listingDisplay].hasOwnProperty('teaserComponent')) {
          return listingConfig[this.listingDisplay].teaserComponent;
        }

        return listingConfig['default'].teaserComponent;
      },
      rowClasses() {
        if(listingConfig[this.listingDisplay].hasOwnProperty('rowClasses')) {
          return listingConfig[this.listingDisplay].rowClasses;
        }

        return listingConfig['default'].rowClasses;
      },
      teaserWrapperClasses() {
        if(listingConfig[this.listingDisplay].hasOwnProperty('teaserWrapperClasses')) {
          return listingConfig[this.listingDisplay].teaserWrapperClasses;
        }

        return listingConfig['default'].teaserWrapperClasses;
      },
      itemsPerRow() {
        if(listingConfig[this.listingDisplay].hasOwnProperty('itemsPerRow')) {
          return listingConfig[this.listingDisplay].itemsPerRow;
        }

        return listingConfig['default'].itemsPerRow;
      },
      shouldShowImage() {
        if(listingConfig[this.listingDisplay].hasOwnProperty('showImage')) {
          return listingConfig[this.listingDisplay].showImage;
        }

        return listingConfig['default'].showImage;
      },
      resultChunks() {
        let values = Object.keys(this.results).map(key => this.results[key]);

        return _chunk(values, this.itemsPerRow);
      },
    },
  }
</script>