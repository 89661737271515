function get(url) {
  const requestOptions = {
      method: 'GET'
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function post(url, body, csrfToken) {
  const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken,
      },
      body: JSON.stringify(body)
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function put(url, body, csrfToken) {
  const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken,
      },
      body: JSON.stringify(body)
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscore because delete is a reserved word in javascript
function _delete(url) {
  const requestOptions = {
      method: 'DELETE'
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// helper functions

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    const error = new Error(response.statusText)
    error.response = response;
    throw error
  }
}

export const fetchWrapper = {
  get,
  post,
  put,
  delete: _delete
};
